// File: src/utils/hotelRateCalculations.js
// Purpose: Helper functions for processing hotel rate data.
//          Includes functions to map raw rates into daily rates and calculate totals.

import moment from "moment";

/**
 * Maps raw rates into a daily rate structure with corresponding dates.
 * @param {Array} rates - The raw rate data from the API.
 * @param {string} startDate - The start date for the selected date range (YYYY-MM-DD).
 * @param {string} endDate - The end date for the selected date range (YYYY-MM-DD).
 * @returns {Array} - Array of daily rates with dates.
 */
export const mapRatesToDaily = (rates, startDate, endDate) => {
  const dailyRates = [];
  const start = new Date(startDate);
  const end = new Date(endDate);

  for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    const currentDate = moment(d).format("YYYY-MM-DD");

    const applicableRate = rates.find(
      (rate) =>
        new Date(rate.start_date) <= d && new Date(rate.end_date) >= d
    );

    dailyRates.push(
      applicableRate
        ? {
            date: currentDate,
            net_rate: parseFloat(applicableRate.net_rate_with_taxes),
            rack_rate: parseFloat(applicableRate.rack_rate_with_taxes),
            extra_adults: parseFloat(applicableRate.net_rate_for_add_person || 0),
            extra_children: parseFloat(applicableRate.net_rate_for_add_child || 0),
          }
        : {
            date: currentDate,
            net_rate: null,
            rack_rate: null,
            message: "No rate available",
          }
    );
  }

  return dailyRates;
};

/**
 * Calculates the total net and rack rates from daily rates.
 * @param {Array} dailyRates - Array of daily rates.
 * @returns {Object} - An object with totalNet and totalRack values.
 */
export const calculateTotals = (dailyRates) => {
  const totalNet = dailyRates.reduce(
    (sum, rate) => (rate.net_rate ? sum + rate.net_rate : sum),
    0
  );

  const totalRack = dailyRates.reduce(
    (sum, rate) => (rate.rack_rate ? sum + rate.rack_rate : sum),
    0
  );

  return {
    totalNet: parseFloat(totalNet).toFixed(2),
    totalRack: parseFloat(totalRack).toFixed(2),
  };
};