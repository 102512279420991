// File: src/components/Dashboard/DomesticFlights/DomesticFlightsRatesTable.js
// Purpose: Displays the rates for a selected route in a tabular format.

import React, { useEffect } from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import {
  selectRates,
  selectIsLoadingRates,
  selectRatesError,
} from '../../../redux/slices/domesticFlightsSelectors';
import styles from './domesticFlights.module.css';
import { addLog } from './LogViewer'; // Import addLog for debugging

const DomesticFlightsRatesTable = () => {
  const rates = useSelector(selectRates);
  const loadingRates = useSelector(selectIsLoadingRates);
  const ratesError = useSelector(selectRatesError);

  // Log state changes for debugging
  useEffect(() => {
    addLog('Component', 'DomesticFlightsRatesTable rendered with current state', {
      rates,
      loadingRates,
      ratesError,
    });
  }, [rates, loadingRates, ratesError]);

  const columns = [
    {
      title: 'Pickup Location',
      dataIndex: 'origin_airport',
      key: 'origin_airport',
      render: (origin) => origin || 'N/A', // Display the airport code or fallback
    },
    {
      title: 'Dropoff Location',
      dataIndex: 'destination_airport',
      key: 'destination_airport',
      render: (destination) => destination || 'N/A', // Display the airport code or fallback
    },
    {
      title: 'Provider',
      dataIndex: 'provider_name',
      key: 'provider_name',
      render: (provider) => provider || 'N/A', // Display the provider name or fallback
    },
    {
      title: 'Adult (Net)',
      dataIndex: 'adult_net',
      key: 'adult_net',
      render: (value) => (value ? `$${value.toFixed(2)}` : 'N/A'),
    },
    {
      title: 'Adult (Rack)',
      dataIndex: 'adult_rack',
      key: 'adult_rack',
      render: (value) => (value ? `$${value.toFixed(2)}` : 'N/A'),
    },
    {
      title: 'Child (Net)',
      dataIndex: 'child_net',
      key: 'child_net',
      render: (value) => (value ? `$${value.toFixed(2)}` : 'N/A'),
    },
    {
      title: 'Child (Rack)',
      dataIndex: 'child_rack',
      key: 'child_rack',
      render: (value) => (value ? `$${value.toFixed(2)}` : 'N/A'),
    },
  ];

  return (
    <div className={styles.ratesTableWrapper}>
      {loadingRates ? (
        <p className={styles.loadingMessage}>Loading rates...</p>
      ) : ratesError ? (
        <p className={styles.errorMessage}>Error: {ratesError}</p>
      ) : (
        <Table
          dataSource={rates.map((rate, index) => ({ key: index, ...rate }))}
          columns={columns}
          pagination={false}
          bordered
          className={styles.ratesTable}
        />
      )}
    </div>
  );
};

export default DomesticFlightsRatesTable;