// File: src/services/domestic_flights_api.js
// Purpose: Provides utility functions for backend API calls related to Domestic Flights, including data fetching and parameter validation.

import axiosInstance from './axios'; // Import preconfigured Axios instance
import { addLog } from '../components/Dashboard/DomesticFlights/LogViewer'; // Import addLog for centralized logging

/**
 * Flattens nested query parameters to a single-level object.
 * @param {object} params - The query parameters.
 * @returns {object} - Flattened parameters.
 */
const flattenParams = (params) => {
  const flatParams = {};
  for (const key in params) {
    if (typeof params[key] === 'object' && params[key] !== null) {
      Object.assign(flatParams, flattenParams(params[key])); // Recursively flatten nested objects
    } else {
      flatParams[key] = params[key];
    }
  }
  addLog('Utility', 'Flattened query parameters', { original: params, flattened: flatParams });
  return flatParams;
};

/**
 * Validates the parameters for an API request against predefined schemas.
 * Throws an error if required parameters are missing.
 * @param {string} endpoint - The API endpoint.
 * @param {object} params - The query parameters for the request.
 */
const validateParams = (endpoint, params) => {
  const schema = getParamSchema(endpoint);
  for (const key in schema) {
    if (schema[key].required && !(key in params)) {
      const errorMessage = `Missing required parameter "${key}" for endpoint "${endpoint}"`;
      addLog('Validation', errorMessage, { params, schema });
      throw new Error(errorMessage);
    }
  }
  addLog('Validation', `Parameters validated for endpoint "${endpoint}"`, { params });
};

/**
 * Retrieves the parameter schema for a given API endpoint.
 * @param {string} endpoint - The API endpoint.
 * @returns {object} - Schema defining required and optional parameters.
 */
const getParamSchema = (endpoint) => {
  const schemas = {
    '/domestic-flights/pickup-locations/': { date: { required: true } },
    '/domestic-flights/dropoff-locations/': {
      date: { required: true },
      pickup_location: { required: true },
    },
    '/domestic-flights/pricing/': {
      date: { required: true },
      pickup_location: { required: true },
      dropoff_location: { required: true },
    },
    '/domestic-flights/provider-policy/': { pricing_id: { required: true } },
  };
  const schema = schemas[endpoint] || {};
  addLog('Utility', `Retrieved schema for endpoint "${endpoint}"`, { schema });
  return schema;
};

/**
 * Logs errors for API requests and provides detailed error information.
 * @param {string} endpoint - The API endpoint.
 * @param {Error} error - The error object.
 */
const handleError = (endpoint, error) => {
  const errorDetails = error.response
    ? { status: error.response.status, data: error.response.data }
    : { message: error.message || 'Unknown error', stack: error.stack };

  addLog('API', `API request failed for ${endpoint}`, errorDetails);

  if (error.response) {
    addLog('API', `API response error for ${endpoint}`, { responseData: error.response.data });
  } else if (error.request) {
    addLog('API', `No response received for ${endpoint}`, { request: error.request });
  } else {
    addLog('API', `Unexpected error during API request for ${endpoint}`, { error: error.message });
  }

  console.error(`API request failed for ${endpoint}:`, error);
};

/**
 * Fetches pickup locations by the selected date.
 * @param {string} selectedDate - The date for which to fetch pickup locations (YYYY-MM-DD).
 * @returns {Promise<object>} - The raw API response data.
 */
export async function fetchPickupLocationsByDate(selectedDate) {
  validateParams('/domestic-flights/pickup-locations/', { date: selectedDate });
  try {
    addLog('API', 'Fetching pickup locations', { selectedDate });
    const response = await axiosInstance.get('/domestic-flights/pickup-locations/', {
      params: { date: selectedDate },
    });
    addLog('API', 'Pickup locations fetched successfully', { data: response.data });
    return response.data;
  } catch (error) {
    handleError('/domestic-flights/pickup-locations/', error);
    throw error;
  }
}

/**
 * Fetches dropoff locations by the selected date and pickup location.
 * @param {string} selectedDate - The selected date (YYYY-MM-DD).
 * @param {string} pickupLocationId - The ID of the selected pickup location.
 * @returns {Promise<object>} - The raw API response data.
 */
export async function fetchDropoffLocationsByPickup(selectedDate, pickupLocationId) {
  validateParams('/domestic-flights/dropoff-locations/', {
    date: selectedDate,
    pickup_location: pickupLocationId,
  });
  try {
    addLog('API', 'Fetching dropoff locations', { selectedDate, pickupLocationId });
    const response = await axiosInstance.get('/domestic-flights/dropoff-locations/', {
      params: { date: selectedDate, pickup_location: pickupLocationId },
    });
    addLog('API', 'Dropoff locations fetched successfully', { data: response.data });
    return response.data;
  } catch (error) {
    handleError('/domestic-flights/dropoff-locations/', error);
    throw error;
  }
}

/**
 * Fetches pricing for a selected route.
 * @param {string} selectedDate - The selected date (YYYY-MM-DD).
 * @param {string} pickupLocationId - The ID of the selected pickup location.
 * @param {string} dropoffLocationId - The ID of the selected dropoff location.
 * @returns {Promise<object>} - The raw API response data.
 */
export async function fetchFlightPricingByRoute(selectedDate, pickupLocationId, dropoffLocationId) {
  validateParams('/domestic-flights/pricing/', {
    date: selectedDate,
    pickup_location: pickupLocationId,
    dropoff_location: dropoffLocationId,
  });
  try {
    addLog('API', 'Fetching flight pricing', {
      selectedDate,
      pickupLocationId,
      dropoffLocationId,
    });
    const response = await axiosInstance.get('/domestic-flights/pricing/', {
      params: {
        date: selectedDate,
        pickup_location: pickupLocationId,
        dropoff_location: dropoffLocationId,
      },
    });
    addLog('API', 'Flight pricing fetched successfully', { data: response.data });
    return response.data;
  } catch (error) {
    handleError('/domestic-flights/pricing/', error);
    throw error;
  }
}

/**
 * Fetches provider policy by pricing ID.
 * @param {string} pricingId - The pricing ID to fetch the policy for.
 * @returns {Promise<object>} - The raw API response data.
 */
export async function fetchPolicyTextByPricingId(pricingId) {
  validateParams('/domestic-flights/provider-policy/', { pricing_id: pricingId });
  try {
    addLog('API', 'Fetching provider policy', { pricingId });
    const response = await axiosInstance.get('/domestic-flights/provider-policy/', {
      params: { pricing_id: pricingId },
    });
    addLog('API', 'Provider policy fetched successfully', { data: response.data });
    return response.data;
  } catch (error) {
    handleError('/domestic-flights/provider-policy/', error);
    throw error;
  }
}

export const apiUtils = {
  fetchPickupLocationsByDate,
  fetchDropoffLocationsByPickup,
  fetchFlightPricingByRoute,
  fetchPolicyTextByPricingId,
};