// File: src/redux/slices/domesticFlightsSelectors.js
// Purpose: Centralizes reusable selectors for the Domestic Flights feature, including location names for routes.

// Selected Date
export const selectSelectedDate = (state) => state.domesticFlightsData.selectedDate;

// Pickup Locations
export const selectPickupLocations = (state) => state.domesticFlightsData.pickupLocations;
export const selectIsLoadingPickupLocations = (state) => state.domesticFlightsData.loadingPickupLocations;
export const selectPickupLocationsError = (state) => state.domesticFlightsData.errorPickupLocations;

// Dropoff Locations
export const selectDropoffLocations = (state) => state.domesticFlightsData.dropoffLocations;
export const selectIsLoadingDropoffLocations = (state) => state.domesticFlightsData.loadingDropoffLocations;
export const selectDropoffLocationsError = (state) => state.domesticFlightsData.errorDropoffLocations;

// Selected Locations
export const selectSelectedPickupLocationId = (state) => state.domesticFlightsData.selectedPickupLocationId;
export const selectSelectedDropoffLocationId = (state) => state.domesticFlightsData.selectedDropoffLocationId;

// Pickup and Dropoff Location Names
export const selectPickupLocationName = (state) => {
  const pickupLocationId = state.domesticFlightsData.selectedPickupLocationId;
  const pickupLocations = state.domesticFlightsData.pickupLocations;
  const location = pickupLocations.find((loc) => loc.id === pickupLocationId);
  return location ? location.name : 'N/A';
};

export const selectDropoffLocationName = (state) => {
  const dropoffLocationId = state.domesticFlightsData.selectedDropoffLocationId;
  const dropoffLocations = state.domesticFlightsData.dropoffLocations;
  const location = dropoffLocations.find((loc) => loc.id === dropoffLocationId);
  return location ? location.name : 'N/A';
};

// Rates
export const selectRates = (state) => state.domesticFlightsOperations.rates;
export const selectIsLoadingRates = (state) => state.domesticFlightsOperations.loadingRates;
export const selectRatesError = (state) => state.domesticFlightsOperations.errorRates;
export const selectProviderName = (state) => {
  const rates = state.domesticFlightsOperations.rates;
  return rates.length > 0 ? rates[0].provider_name : 'Unknown Provider';
};

// Policy
export const selectPolicyText = (state) => {
  const policyText = typeof state.domesticFlightsOperations.policy === 'string'
    ? state.domesticFlightsOperations.policy
    : '';
  return policyText;
};

export const selectIsLoadingPolicy = (state) => state.domesticFlightsOperations.loadingPolicy;
export const selectPolicyError = (state) => state.domesticFlightsOperations.errorPolicy;

// Quotes
export const selectSelectedPricingId = (state) => state.domesticFlightsOperations.selectedPricingId;
export const selectAdditionalAmount = (state) => state.domesticFlightsOperations.additionalAmount;
export const selectNetQuote = (state) => state.domesticFlightsOperations.netQuote;
export const selectRackQuote = (state) => state.domesticFlightsOperations.rackQuote;

// Combined Quotes Selector (Optional)
export const selectQuotes = (state) => ({
  net: state.domesticFlightsOperations.netQuote,
  rack: state.domesticFlightsOperations.rackQuote,
});