// File: src/components/Dashboard/DomesticFlights/DomesticFlightsSearchBar.js
// Purpose: Provides a search bar with a date picker and dropdowns for selecting pickup and dropoff locations.
// Related files:
//   - src/components/Dashboard/DomesticFlights/DomesticFlightsDatePicker.js: Date picker for selecting the flight date.
//   - src/components/Dashboard/DomesticFlights/DomesticFlightsOriginDropdown.js: Dropdown for selecting the pickup location.
//   - src/components/Dashboard/DomesticFlights/DomesticFlightsDestinationDropdown.js: Dropdown for selecting the dropoff location.
//   - ../../../redux/slices/domesticFlightsDataSlice.js: Manages state for date, pickup, and dropoff locations.

import React from 'react';
import DomesticFlightsDatePicker from './DomesticFlightsDatePicker';
import DomesticFlightsOriginDropdown from './DomesticFlightsOriginDropdown';
import DomesticFlightsDestinationDropdown from './DomesticFlightsDestinationDropdown';
import { useSelector } from 'react-redux';
import { selectSelectedDate } from '../../../redux/slices/domesticFlightsSelectors';
import styles from './domesticFlights.module.css';
import { addLog } from './LogViewer'; // Import addLog for debugging

const DomesticFlightsSearchBar = () => {
  const selectedDate = useSelector(selectSelectedDate);

  // Log the selected date whenever it changes
  React.useEffect(() => {
    addLog('Component', 'DomesticFlightsSearchBar rendered with selected date', { selectedDate });
  }, [selectedDate]);

  return (
    <div className={styles.searchBar}>
      {/* Date Picker */}
      <div className={styles.datePicker}>
        <DomesticFlightsDatePicker />
      </div>

      {/* Dropdown for Pickup Locations */}
      <div className={styles.originDropdown}>
        <DomesticFlightsOriginDropdown />
      </div>

      {/* Dropdown for Dropoff Locations */}
      <div className={styles.destinationDropdown}>
        <DomesticFlightsDestinationDropdown />
      </div>
    </div>
  );
};

export default DomesticFlightsSearchBar;