// File: src/components/Dashboard/Tours/ToursRegionDropdown.js
// Purpose: Dropdown to select regions, automatically clearing associated activities and related state when the region changes.
// Files it interacts with:
//   - src/redux/slices/toursDataSlice.js: Manages regions and related state.
//   - src/redux/slices/toursSelectors.js: Provides selectors for regions and related state.
//   - src/styles/Tours.module.css: Styles for the dropdown.

import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRegions, setSelectedRegionId } from '../../../redux/slices/toursDataSlice';
import {
    selectRegions,
    selectLoadingRegions,
    selectErrorRegions,
    selectSelectedYearId,
    selectSelectedRegionId,
} from '../../../redux/slices/toursSelectors';
import styles from 'styles/Tours.module.css';

const { Option } = Select;

const ToursRegionDropdown = () => {
    const dispatch = useDispatch();
    const regions = useSelector(selectRegions);
    const loadingRegions = useSelector(selectLoadingRegions);
    const errorRegions = useSelector(selectErrorRegions);
    const selectedYearId = useSelector(selectSelectedYearId);
    const selectedRegionId = useSelector(selectSelectedRegionId);

    useEffect(() => {
        if (selectedYearId) {
            dispatch(fetchRegions(selectedYearId));
        } else {
            dispatch(setSelectedRegionId(null)); // Clear selected region if no year is selected
        }
    }, [selectedYearId, dispatch]);

    const handleRegionSelect = (value) => {
        dispatch(setSelectedRegionId(value)); // Automatically clears activities via slice logic
    };

    return (
        <div className={styles.selectWrapper}>
            <Select
                showSearch
                value={selectedRegionId}
                onChange={handleRegionSelect}
                placeholder={
                    loadingRegions
                        ? 'Loading regions...'
                        : errorRegions
                        ? 'Data fetch failed'
                        : 'Select a region'
                }
                allowClear
                className={`${styles.antSelect} ${styles.wideSelect}`}
                style={{ width: '100%' }}
                notFoundContent={loadingRegions ? 'Loading regions...' : 'No regions available'}
                disabled={!selectedYearId}
                filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                }
            >
                {regions.map(({ id, name }) => (
                    <Option key={id} value={id}>
                        {name}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default ToursRegionDropdown;