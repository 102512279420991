// File: src/services/domesticFlightsTransformers.js
// Purpose: Transform API responses into usable frontend formats.

export const transformPickupLocations = (rawData) => {
  if (!Array.isArray(rawData)) return [];
  return rawData.map((item) => ({
    id: item.id || null,
    name: item.name || 'Unknown Location',
  }));
};

export const transformDropoffLocations = (rawData) => {
  if (!Array.isArray(rawData)) return [];
  return rawData.map((item) => ({
    id: item.id || null,
    name: item.name || 'Unknown Location',
  }));
};

export const transformFlightRates = (rawData) => {
  const normalizedData = Array.isArray(rawData) ? rawData : [rawData];
  if (!Array.isArray(normalizedData)) return [];
  return normalizedData.map((item) => ({
    id: item.id || null,
    rate_start_date: item.rate_start_date || null,
    rate_end_date: item.rate_end_date || null,
    provider_name: item.provider?.name || 'Unknown Provider',
    origin_airport: item.origin_airport?.name || 'Unknown Origin', // Extract name directly
    destination_airport: item.destination_airport?.name || 'Unknown Destination', // Extract name directly
    adult_net: parseFloat(item.adult_net || 0),
    adult_rack: parseFloat(item.adult_rack || 0),
    child_net: parseFloat(item.child_net || 0),
    child_rack: parseFloat(item.child_rack || 0),
  }));
};

export const transformPolicies = (rawData) => {
  if (typeof rawData !== 'object' || rawData === null) {
    return { policy: 'No policy available.' };
  }
  return {
    policy: rawData.policy_text || 'No policy available.',
    provider: rawData.provider?.name || 'Unknown Provider',
  };
};

export default {
  transformPickupLocations,
  transformDropoffLocations,
  transformFlightRates,
  transformPolicies,
};