// File: src/components/Dashboard/RentalCars/RentalCarsRateDisplay.js
// Purpose: Displays rental car rates in a table format using Ant Design components.

import React from 'react';
import { useRentalCars } from 'components/Dashboard/RentalCars/RentalCarsContext';
import { Table, Typography } from 'antd';
import styles from 'styles/RentalCars.module.css';

const { Title } = Typography;

const RentalCarsRateDisplay = () => {
  const { rates } = useRentalCars();

  if (!rates.length) {
    return (
      <div className={styles.rateDisplay}>
        <Typography.Text>Rates are loading...</Typography.Text>
      </div>
    );
  }

  // Columns configuration for Ant Design Table
  const columns = [
    {
      title: 'Car',
      dataIndex: 'car',
      key: 'car',
    },
    {
      title: 'High Season (Daily Rack)',
      dataIndex: ['seasons', 0, 'daily_rack_rate'],
      key: 'highSeasonRack',
      render: (value) => `$${parseFloat(value).toFixed(2) || 'N/A'}`,
    },
    {
      title: 'High Season (Daily Net)',
      dataIndex: ['seasons', 0, 'daily_net_rate'],
      key: 'highSeasonNet',
      render: (value) => `$${parseFloat(value).toFixed(2) || 'N/A'}`,
    },
    {
      title: 'Green Season (Daily Rack)',
      dataIndex: ['seasons', 1, 'daily_rack_rate'],
      key: 'greenSeasonRack',
      render: (value) => `$${parseFloat(value).toFixed(2) || 'N/A'}`,
    },
    {
      title: 'Green Season (Daily Net)',
      dataIndex: ['seasons', 1, 'daily_net_rate'],
      key: 'greenSeasonNet',
      render: (value) => `$${parseFloat(value).toFixed(2) || 'N/A'}`,
    },
    {
      title: 'Promo Season (Daily Rack)',
      dataIndex: ['seasons', 2, 'daily_rack_rate'],
      key: 'promoSeasonRack',
      render: (value) => `$${parseFloat(value).toFixed(2) || 'N/A'}`,
    },
    {
      title: 'Promo Season (Daily Net)',
      dataIndex: ['seasons', 2, 'daily_net_rate'],
      key: 'promoSeasonNet',
      render: (value) => `$${parseFloat(value).toFixed(2) || 'N/A'}`,
    },
  ];

  return (
    <div className={styles.rateDisplay}>
      <Title level={3} className={styles.centerTitle}>
        Rental Car Rates
      </Title>
      <Table
        dataSource={rates}
        columns={columns}
        rowKey={(rate) => rate.id}
        pagination={false}
        className={styles.rateTable}
      />
    </div>
  );
};

export default RentalCarsRateDisplay;
