// File: src/redux/slices/occupancySlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adults: 1,
  children: 0,
  childrenAges: [],
  constraints: {
    maxAdults: Infinity,
    minAdults: 1,
    maxChildren: Infinity,
    minChildren: 0,
  },
};

const occupancySlice = createSlice({
  name: "occupancy",
  initialState,
  reducers: {
    setAdults(state, action) {
      state.adults = action.payload;
    },
    setChildren(state, action) {
      const numberOfChildren = action.payload;
      state.children = numberOfChildren;

      if (numberOfChildren > state.childrenAges.length) {
        state.childrenAges = [
          ...state.childrenAges,
          ...Array(numberOfChildren - state.childrenAges.length).fill(""),
        ];
      } else {
        state.childrenAges = state.childrenAges.slice(0, numberOfChildren);
      }
    },
    setChildAge(state, action) {
      const { index, age } = action.payload;
      if (index >= 0 && index < state.childrenAges.length) {
        state.childrenAges[index] = age;
      }
    },
    setConstraints(state, action) {
      state.constraints = {
        ...state.constraints,
        ...action.payload, // Merge with existing constraints
      };
    },
  },
});

export const { setAdults, setChildren, setChildAge, setConstraints } = occupancySlice.actions;

export default occupancySlice.reducer;