// File: src/utils/toursQuoteCalculator.js
// Purpose: Utility function to calculate net and rack quotes based on user inputs and tour rates.
// Related files:
//   - src/redux/slices/toursOperationsSlice.js: Manages user inputs and triggers quote calculations.
//   - src/components/Dashboard/Tours/ToursQuoteGeneratorSection.js: Provides UI for inputting values for quote calculation.

export const calculateTourQuotes = ({ 
    regularAdults = 0, 
    regularChildren = 0, 
    privateAdults = 0, 
    privateChildren = 0, 
    additionalAmount = 0, 
    rates = {}
}) => {
    const netQuote =
        regularAdults * (rates.reg_adults_net || 0) +
        regularChildren * (rates.reg_children_net || 0) +
        privateAdults * (rates.priv_adults_net || 0) +
        privateChildren * (rates.priv_children_net || 0) +
        additionalAmount;

    const rackQuote =
        regularAdults * (rates.reg_adults_rack || 0) +
        regularChildren * (rates.reg_children_rack || 0) +
        privateAdults * (rates.priv_adults_rack || 0) +
        privateChildren * (rates.priv_children_rack || 0) +
        additionalAmount;

    return { netQuote, rackQuote };
};