// File: src/components/Dashboard/Tours/ToursRatesTable.js
// Purpose: Displays the rates for a selected tour in a tabular format.
// Related files:
//   - ToursRatesSection.js: Passes rates data to this component for rendering.
//   - src/redux/slices/toursSelectors.js: Provides the selected activity name from Redux state.

import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import styles from 'styles/Tours.module.css';
import { selectSelectedActivityName } from '../../../redux/slices/toursSelectors';

const ToursRatesTable = ({ rates }) => {
    const activityName = useSelector(selectSelectedActivityName);
    console.log('ToursRatesTable: Rendering table with rates:', rates, 'Activity Name:', activityName);

    const columns = [
        {
            title: 'Activity',
            dataIndex: 'activity',
            key: 'activity',
            render: () => activityName || 'N/A', // Display the activity name
        },
        {
            title: 'Regular Adults (Net)',
            dataIndex: 'reg_adults_net',
            key: 'reg_adults_net',
        },
        {
            title: 'Regular Adults (Rack)',
            dataIndex: 'reg_adults_rack',
            key: 'reg_adults_rack',
        },
        {
            title: 'Regular Children (Net)',
            dataIndex: 'reg_children_net',
            key: 'reg_children_net',
        },
        
        
        {
            title: 'Regular Children (Rack)',
            dataIndex: 'reg_children_rack',
            key: 'reg_children_rack',
        },
        {
            title: 'Private Adults (Net)',
            dataIndex: 'priv_adults_net',
            key: 'priv_adults_net',
        },
        {
            title: 'Private Adults (Rack)',
            dataIndex: 'priv_adults_rack',
            key: 'priv_adults_rack',
        },
                {
            title: 'Private Children (Net)',
            dataIndex: 'priv_children_net',
            key: 'priv_children_net',
        },
        
        {
            title: 'Private Children (Rack)',
            dataIndex: 'priv_children_rack',
            key: 'priv_children_rack',
        },        
        {
            title: 'Min Pax',
            dataIndex: 'min_pax',
            key: 'min_pax',
        },
        {
            title: 'Min Age',
            dataIndex: 'min_age',
            key: 'min_age',
        },
        {
            title: 'Provider',
            dataIndex: 'providerName',
            key: 'providerName',
        },
    ];

    return (
        <Table
            dataSource={rates.map((rate, index) => ({ key: index, ...rate }))}
            columns={columns}
            pagination={false}
            bordered
            className={styles.rateTable}
        />
    );
};

export default ToursRatesTable;