// File: src/components/Dashboard/DomesticFlights/DomesticFlightsQuoteGenerator.js
// Purpose: Generates a quote for the selected route and date based on user inputs and flight pricing data,
// and formats it for easy pasting into Google Sheets.

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  selectRates,
  selectPickupLocationName,
  selectDropoffLocationName,
  selectProviderName,
} from '../../../redux/slices/domesticFlightsSelectors';
import { Button, InputNumber } from 'antd';
import styles from './domesticFlights.module.css';

const calculateQuote = (rates, adultCount, childCount, additionalAmount) => {
  if (!rates || rates.length === 0) return { net: 0, rack: 0 };

  const rate = rates[0];
  const net =
    adultCount * parseFloat(rate.adult_net || 0) +
    childCount * parseFloat(rate.child_net || 0) +
    additionalAmount;

  const rack =
    adultCount * parseFloat(rate.adult_rack || 0) +
    childCount * parseFloat(rate.child_rack || 0) +
    additionalAmount;

  return { net, rack };
};

const DomesticFlightsQuoteGenerator = () => {
  const rates = useSelector(selectRates);
  const pickupLocationName = useSelector(selectPickupLocationName);
  const dropoffLocationName = useSelector(selectDropoffLocationName);
  const providerName = useSelector(selectProviderName);

  const [adultCount, setAdultCount] = useState(0);
  const [childCount, setChildCount] = useState(0);
  const [additionalAmount, setAdditionalAmount] = useState(0);
  const [netQuote, setNetQuote] = useState(0);
  const [rackQuote, setRackQuote] = useState(0);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const { net, rack } = calculateQuote(rates, adultCount, childCount, additionalAmount);
    setNetQuote(net);
    setRackQuote(rack);
  }, [adultCount, childCount, additionalAmount, rates]);

  const handleCopyToClipboard = () => {
    const date = new Date().toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).replace(/ /g, '-');

    const route = `${pickupLocationName || 'N/A'} to ${dropoffLocationName || 'N/A'}`;
    const paxDescription = `${adultCount > 0 ? `${adultCount} Adult${adultCount > 1 ? 's' : ''}` : ''}${
      adultCount > 0 && childCount > 0 ? ', ' : ''
    }${childCount > 0 ? `${childCount} Child${childCount > 1 ? 'ren' : ''}` : ''}`;
    const formattedRoute = `${route}${paxDescription ? ` (${paxDescription})` : ''}`;
    const netRate = `$${netQuote.toFixed(2)}`;
    const rackRate = `$${rackQuote.toFixed(2)}`;

    const rowData = `${date}\t${providerName}\t${formattedRoute}\t${netRate}\t${rackRate}`;

    navigator.clipboard.writeText(rowData).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  return (
    <div className={styles.quoteGenerator}>
      <h3 className={styles.quoteGeneratorTitle}>Flight Quote Generator</h3>

      {/* Input Rows */}
      <div className={styles.quoteRow}>
        <label>Adult Count:</label>
        <InputNumber
          min={0}
          value={adultCount}
          onChange={(value) => setAdultCount(value || 0)}
        />
      </div>
      <div className={styles.quoteRow}>
        <label>Child Count:</label>
        <InputNumber
          min={0}
          value={childCount}
          onChange={(value) => setChildCount(value || 0)}
        />
      </div>
      <div className={styles.quoteRow}>
        <label>Additional Fees:</label>
        <InputNumber
          min={0}
          value={additionalAmount}
          formatter={(value) => `$ ${value}`}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          onChange={(value) => setAdditionalAmount(value || 0)}
        />
      </div>

      {/* Quote Results */}
      <div className={styles.quoteResults}>
        <table className={styles.previewTable}>
          <tbody>
            <tr>
              <td><strong>Net Quote:</strong></td>
              <td>${netQuote.toFixed(2)}</td>
            </tr>
            <tr>
              <td><strong>Rack Quote:</strong></td>
              <td>${rackQuote.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Copy Button */}
      <Button className={styles.copyQuoteButton} onClick={handleCopyToClipboard}>
        {isCopied ? 'Copied!': 'Copy Quote'}
      </Button>
    </div>
  );
};

export default DomesticFlightsQuoteGenerator;