// File: src/components/Dashboard/Tours/ToursSearchBar.js
// Purpose: Dedicated component for handling dropdown logic and layout.

import React from 'react';
import ToursYearDropdown from './ToursYearDropdown';
import ToursRegionDropdown from './ToursRegionDropdown';
import ToursActivityDropdown from './ToursActivityDropdown';
import styles from 'styles/Tours.module.css';

const ToursSearchBar = () => {
    return (
        <div className={styles.toursSearchBar}>
            <div className={styles.yearDropdown}>
                <ToursYearDropdown />
            </div>
            <div className={styles.regionDropdown}>
                <ToursRegionDropdown />
            </div>
            <div className={styles.activityDropdown}>
                <ToursActivityDropdown />
            </div>
        </div>
    );
};

export default ToursSearchBar;