// File: src/routes/AppRoutes.js
// Purpose: Configures application routes.

import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "components/LoginPage/Login";
import MainDashboard from "components/Dashboard/MainDashboard";
import HotelRatesSearch from "components/Dashboard/HotelRatesSearch";
import TransfersSearchInterface from "components/Dashboard/Transfers/TransfersFrontpage";
import ToursSearchInterface from "components/Dashboard/Tours/ToursFrontpage";
import RentalCarsFrontpage from "components/Dashboard/RentalCars/RentalCarsFrontpage";
import DomesticFlightsFrontpage from "components/Dashboard/DomesticFlights/DomesticFlightsFrontpage"; // New import

const AppRoutes = () => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/dashboard/*" element={<MainDashboard />}>
      {/* Note component displayed on /dashboard */}
      <Route
        index
        element={<div style={{ textAlign: "center", marginTop: "20px" }}>Click a tab to begin</div>}
      />
      <Route path="hotelsearch" element={<HotelRatesSearch />} />
      <Route path="transfers" element={<TransfersSearchInterface />} />
      <Route path="tours" element={<ToursSearchInterface />} />
      <Route path="rentalcars" element={<RentalCarsFrontpage />} />
      <Route path="domesticflights" element={<DomesticFlightsFrontpage />} />
    </Route>
    <Route path="*" element={<Login />} />
  </Routes>
);

export default AppRoutes;