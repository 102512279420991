// File: src/components/Transfers/RateDisplay.js

import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { fetchRates } from 'services/transfers_api';
import { useTransfers } from './TransfersContext';
import styles from 'styles/Transfers.module.css';

const RateDisplay = () => {
    const { yearId, pickupId, dropOffId, pickupName, dropOffName } = useTransfers();
    const [rates, setRates] = useState([]);
    const [buttonStates, setButtonStates] = useState({});

    useEffect(() => {
        const getRates = async () => {
            if (yearId && pickupId && dropOffId) {
                try {
                    const response = await fetchRates(yearId, pickupId, dropOffId);
                    console.log('API Response:', response); // Debugging: Log the full response

                    // Extract data from response
                    const transformedRates = Array.isArray(response.data.results) ? response.data.results : [];
                    setRates(transformedRates);

                    const initialButtonStates = transformedRates.reduce((acc, rate) => {
                        acc[rate.id] = { copyText: 'Copy' };
                        return acc;
                    }, {});
                    setButtonStates(initialButtonStates);
                } catch (error) {
                    console.error('Error fetching rates:', error); // Log any errors
                    setRates([]);
                }
            } else {
                console.log('Missing yearId, pickupId, or dropOffId'); // Debugging: Log missing inputs
                setRates([]);
            }
        };

        getRates();
    }, [yearId, pickupId, dropOffId]);

    const handleCopyToClipboard = (rate) => {
        const textToCopy = `Jacamar\tTransfer from ${pickupName} to ${dropOffName} for ${rate.group_size}\t${rate.net_rate_with_taxes}\t${rate.rack_rate_with_taxes}`;
        navigator.clipboard.writeText(textToCopy).then(() => {
            setButtonStates(prevStates => ({
                ...prevStates,
                [rate.id]: { ...prevStates[rate.id], copyText: 'Copied' }
            }));
            setTimeout(() => {
                setButtonStates(prevStates => ({
                    ...prevStates,
                    [rate.id]: { ...prevStates[rate.id], copyText: 'Copy' }
                }));
            }, 2000);
        });
    };

    const columns = [
        {
            title: 'Group Size',
            dataIndex: 'group_size',
            key: 'group_size',
        },
        {
            title: 'Net Rate with Taxes',
            dataIndex: 'net_rate_with_taxes',
            key: 'net_rate_with_taxes',
            render: (value) => `$${value}`,
        },
        {
            title: 'Rack Rate with Taxes',
            dataIndex: 'rack_rate_with_taxes',
            key: 'rack_rate_with_taxes',
            render: (value) => `$${value}`,
        },
        {
            title: '',
            key: 'copy',
            render: (_, rate) => {
                const buttonState = buttonStates[rate.id] || { copyText: 'Copy' };
                return (
                    <Button
                        onClick={() => handleCopyToClipboard(rate)}
                        type="default"
                        size="small"
                    >
                        {buttonState.copyText}
                    </Button>
                );
            },
        },
    ];

    if (!rates.length) {
        return <div className={styles.rateDisplay}>Select a transfer for rates</div>;
    }

    return (
        <div className={styles.rateDisplay}>
            <Table
                columns={columns}
                dataSource={rates}
                rowKey={(rate) => rate.id}
                pagination={false}
                bordered
                className={styles.rateTable}
            />
        </div>
    );
};

export default RateDisplay;