// File: src/components/Dashboard/Tours/ToursFrontpage.js
// Purpose: Place Quote Generator and Policy Section into a shared box and conditionally render based on selected activity.

import React from 'react';
import { useSelector } from 'react-redux';
import ToursSearchBar from './ToursSearchBar';
import ToursRatesSection from './ToursRatesSection';
import ToursQuoteGeneratorSection from './ToursQuoteGeneratorSection';
import ToursPolicySection from './ToursPolicySection';
import { selectSelectedActivityId } from '../../../redux/slices/toursSelectors';
import styles from 'styles/Tours.module.css';

const ToursFrontpage = () => {
    const selectedActivityId = useSelector(selectSelectedActivityId);

    return (
        <div className={styles.toursFrontpage}>
            {/* Search Bar */}
            <div className={styles.toursSearchBar}>
                <ToursSearchBar />
            </div>

            {/* Shared Box for Quote Generator and Policy Section */}
            {selectedActivityId && (
                <div className={styles.toursQuotePolicyBox}>
                    <div className={styles.quoteGenerator}>
                        <ToursQuoteGeneratorSection />
                    </div>
                    <div className={styles.policySection}>
                        <ToursPolicySection />
                    </div>
                </div>
            )}

            {/* Rates Section */}
            <ToursRatesSection />
        </div>
    );
};

export default ToursFrontpage;