// File: src/components/Dashboard/MainDashboard.js

import React from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import NavigationBar from "components/Layouts/NavigationBar";
import ShoppingCart from "components/Dashboard/ShoppingCart/ShoppingCart";

const { Content } = Layout;

const MainDashboard = () => (
  <Layout style={{ minHeight: "100vh" }}>
    <NavigationBar />
    <Content style={{ padding: "24px", backgroundColor: "#fff" }}>
      <Outlet /> {/* Renders nested routes */}
    </Content>
    <ShoppingCart />
  </Layout>
);

export default MainDashboard;