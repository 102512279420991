// File: src/components/Dashboard/DomesticFlights/DomesticFlightsFrontpage.js
// Purpose: Main entry point for the Domestic Flights dashboard module.

import React from 'react';
import { useSelector } from 'react-redux';
import { selectRates } from '../../../redux/slices/domesticFlightsSelectors';
import DomesticFlightsSearchBar from './DomesticFlightsSearchBar';
import DomesticFlightsRatesSection from './DomesticFlightsRatesSection';
import DomesticFlightsPolicySection from './DomesticFlightsPolicySection';
import DomesticFlightsQuoteGenerator from './DomesticFlightsQuoteGenerator';
import styles from './domesticFlights.module.css'; // Centralized styling for Domestic Flights

const DomesticFlightsFrontpage = () => {
  const rates = useSelector(selectRates); // Get rates from Redux

  return (
    <div className={styles.domesticFlightsFrontpage}>
      {/* Search Bar */}
      <div className={styles.searchBarContainer}>
        <DomesticFlightsSearchBar />
      </div>

      {/* Shared Box for Quote Generator and Policy Section */}
      {rates && rates.length > 0 && (
        <div className={styles.sharedBox}>
          <div className={styles.quoteGenerator}>
            <DomesticFlightsQuoteGenerator />
          </div>
          <div className={styles.policySection}>
            <DomesticFlightsPolicySection />
          </div>
        </div>
      )}

      {/* Rates Section */}
      <DomesticFlightsRatesSection />
    </div>
  );
};

export default DomesticFlightsFrontpage;