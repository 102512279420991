// File: src/components/Dashboard/Tours/ToursActivityDropdown.js
// Purpose: Dropdown to select activities based on the selected region, automatically clearing rates and provider details when the region or activity changes.
// Files it interacts with:
//   - src/redux/slices/toursDataSlice.js: Manages activities and related state.
//   - src/redux/slices/toursOperationsSlice.js: Manages rates, policies, and provider details.
//   - src/redux/slices/toursSelectors.js: Provides selectors for activities and related state.
//   - src/styles/Tours.module.css: Styles for the dropdown.

import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { fetchActivities, setSelectedActivityId } from '../../../redux/slices/toursDataSlice';
import { fetchRates, resetRates } from '../../../redux/slices/toursOperationsSlice';
import { setSelectedProviderId, setSelectedProviderName } from '../../../redux/slices/toursOperationsSlice';
import {
    selectActivities,
    selectLoadingActivities,
    selectErrorActivities,
    selectSelectedRegionId,
    selectSelectedActivityId,
} from '../../../redux/slices/toursSelectors';
import styles from 'styles/Tours.module.css';

const { Option } = Select;

const ToursActivityDropdown = () => {
    const dispatch = useDispatch();
    const activities = useSelector(selectActivities);
    const loadingActivities = useSelector(selectLoadingActivities);
    const errorActivities = useSelector(selectErrorActivities);
    const selectedRegionId = useSelector(selectSelectedRegionId);
    const selectedActivityId = useSelector(selectSelectedActivityId);

    useEffect(() => {
        if (selectedRegionId) {
            dispatch(fetchActivities(selectedRegionId));
        } else {
            dispatch(setSelectedActivityId(null)); // Clear selected activity when region changes
            dispatch(resetRates()); // Reset rates when region is cleared
        }
    }, [selectedRegionId, dispatch]);

    const handleActivitySelect = (selectedValue) => {
        dispatch(setSelectedActivityId(selectedValue));

        if (!selectedValue) {
            dispatch(resetRates()); // Reset rates when activity is cleared
            dispatch(setSelectedProviderId(null));
            dispatch(setSelectedProviderName(''));
            return;
        }

        const selectedActivity = activities.find((activity) => activity.id === selectedValue);

        if (selectedActivity) {
            dispatch(setSelectedProviderId(selectedActivity.providerId || null));
            dispatch(setSelectedProviderName(selectedActivity.providerName || 'Unknown'));
            dispatch(fetchRates(selectedValue)); // Fetch rates only if activity is valid
        }
    };

    return (
        <div className={styles.selectWrapper}>
            <Select
                showSearch
                value={selectedActivityId}
                onChange={handleActivitySelect}
                placeholder={
                    loadingActivities
                        ? 'Loading activities...'
                        : errorActivities
                        ? 'Data fetch failed'
                        : 'Select an activity'
                }
                allowClear
                className={`${styles.antSelect} ${styles.wideSelect}`}
                style={{ width: '100%' }}
                notFoundContent={loadingActivities ? 'Loading...' : 'No activities available'}
                disabled={!selectedRegionId}
                filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                }
            >
                {activities.map(({ id, name }) => (
                    <Option key={id} value={id}>
                        {name}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default ToursActivityDropdown;