// File: src/components/Dashboard/RentalCars/RentalCarsFrontpage.js
// Purpose: Main front page for Rental Cars, integrates Ant Design layout components and child components.

import React from 'react';
import RentalCarsDateComponent from 'components/Dashboard/RentalCars/RentalCarsDateComponent';
import RentalCarsRateDisplay from 'components/Dashboard/RentalCars/RentalCarsRateDisplay';
import RentalCarsQuoteGenerator from 'components/Dashboard/RentalCars/RentalCarsQuoteGenerator';
import { useRentalCars, RentalCarsProvider } from 'components/Dashboard/RentalCars/RentalCarsContext';
import { Layout, Row, Col, Typography } from 'antd';

const { Content } = Layout;
const { Title } = Typography;

const RentalCarsFrontpageContent = () => {
  const { startDate, endDate } = useRentalCars();

  return (
    <Content style={{ padding: '20px' }}>
      <Row gutter={[16, 16]} justify="center">
        <Col span={24}>
          <Title level={2} style={{ textAlign: 'center' }}>
            Rental Cars
          </Title>
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}>
          <RentalCarsDateComponent />
        </Col>
        {/* Uncomment to include the Rate Display */}
        {/* 
        <Col span={24}>
          <RentalCarsRateDisplay />
        </Col> 
        */}
        <Col span={24}>
          <RentalCarsQuoteGenerator />
        </Col>
      </Row>
    </Content>
  );
};

const RentalCarsFrontpage = () => (
  <RentalCarsProvider>
    <Layout style={{ minHeight: '100vh' }}>
      <RentalCarsFrontpageContent />
    </Layout>
  </RentalCarsProvider>
);

export default RentalCarsFrontpage;