// File: src/components/Dashboard/RentalCars/RentalCarsDateComponent.js
// Purpose: Provides a date picker for selecting start and end dates, updating the rental car context state.

import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useRentalCars } from 'components/Dashboard/RentalCars/RentalCarsContext';
import styles from 'styles/RentalCars.module.css';

const { RangePicker } = DatePicker;

const RentalCarsDateComponent = () => {
  const { startDate, endDate, onDateSelect } = useRentalCars();

  const handleDateChange = (dates) => {
    const [start, end] = dates || [];
    const formattedStart = start ? start.format('YYYY-MM-DD') : null;
    const formattedEnd = end ? end.format('YYYY-MM-DD') : null;
    onDateSelect({ start: formattedStart, end: formattedEnd });
  };

  return (
    <div className={styles.datePickerContainer}>
      <RangePicker
        className={styles.datePicker}
        value={[
          startDate ? dayjs(startDate, 'YYYY-MM-DD') : null,
          endDate ? dayjs(endDate, 'YYYY-MM-DD') : null,
        ]}
        onChange={handleDateChange}
        placeholder={['Start Date', 'End Date']}
        format="DD-MMM-YYYY"
        allowClear
      />
    </div>
  );
};

export default RentalCarsDateComponent;
