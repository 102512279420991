// File: src/components/Dashboard/DomesticFlights/DomesticFlightsPolicySection.js
// Purpose: Displays the policy information for the selected flight provider.

import React from 'react';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { selectPolicyText } from '../../../redux/slices/domesticFlightsSelectors';
import styles from './domesticFlights.module.css';

const { Title } = Typography;

const DomesticFlightsPolicySection = () => {
  const policyText = useSelector(selectPolicyText);

  if (!policyText) {
    return null; // Do nothing if policy text is not available
  }

  return (
    <div className={styles.policyContainer}>
      <Title level={3}>Provider Policy</Title>
      <div
        className={styles.policyContent}
        dangerouslySetInnerHTML={{ __html: policyText }}
      />
    </div>
  );
};

export default DomesticFlightsPolicySection;