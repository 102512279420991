// File: src/components/App/App.js
// Purpose: Entry point for the React application,
//          integrates Redux, routing, and context providers.

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { AuthProvider } from "components/Common/AuthContext";
import { ShoppingCartProvider } from "components/Dashboard/ShoppingCart/ShoppingCartContext";
import AppRoutes from "routes/AppRoutes";
import { ConfigProvider } from "antd";
import { ToastContainer } from "react-toastify";
import { store } from "../../redux/store";
import "react-toastify/dist/ReactToastify.css";

const App = () => (
  <BrowserRouter
    future={{
      v7_startTransition: true, // React Router compatibility
      v7_relativeSplatPath: true, // React Router compatibility
    }}
  >
    <Provider store={store}>
      <ConfigProvider>
        <AuthProvider>
          <ShoppingCartProvider>
            <AppRoutes />
            <ToastContainer />
          </ShoppingCartProvider>
        </AuthProvider>
      </ConfigProvider>
    </Provider>
  </BrowserRouter>
);

export default App;