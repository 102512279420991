// File: src/components/Transfers/SelectYearDropdown.js

import React, { useState, useEffect } from 'react';
import Select from 'antd/lib/select';
import { fetchAvailableYears } from 'services/transfers_api';
import { useTransfers } from './TransfersContext';
import styles from 'styles/Transfers.module.css';

const { Option } = Select;

const SelectYearDropdown = () => {
    const { yearId, handleYearChange } = useTransfers();
    const [availableYears, setAvailableYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getAvailableYears = async () => {
            setLoading(true); // Set loading to true
            try {
                const response = await fetchAvailableYears();
                const transformedYears = response?.data?.results || [];
                setAvailableYears(transformedYears);

                if (transformedYears.length > 0 && !yearId) {
                    handleYearChange(transformedYears[0].id);
                }
            } catch (error) {
                setAvailableYears([]);
            } finally {
                setLoading(false); // Set loading to false after data fetch
            }
        };

        getAvailableYears();
    }, []); // Run once on mount

    const handleYearSelect = (value, option) => {
        setSelectedYear(option);
        handleYearChange(value);
    };

    return (
        <Select
            className={styles.reactSelectControl}
            value={selectedYear ? selectedYear.value : null}
            onChange={handleYearSelect}
            placeholder="Select a year"
            allowClear
            style={{
                width: '100%',
                minWidth: '110px',
            }}
            notFoundContent={loading ? "Loading..." : "No data available"} // Custom message
        >
            {availableYears.map(year => (
                <Option key={year.id} value={year.id}>
                    {year.year}
                </Option>
            ))}
        </Select>
    );
};

export default SelectYearDropdown;