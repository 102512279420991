// File: src/redux/slices/domesticFlightsOperationsSlice.js
// Purpose: Manages state for flight rates, quotes, and provider policies with decoupled actions, enhanced error handling, and comprehensive logging for debugging.

import { createSlice } from '@reduxjs/toolkit';
import { addLog } from '../../components/Dashboard/DomesticFlights/LogViewer'; // Import addLog

const initialState = {
  rates: [],
  policy: null,
  loadingRates: false,
  loadingPolicy: false,
  errorRates: null,
  errorPolicy: null,
  selectedPricingId: null,
  additionalAmount: 0,
  netQuote: 0,
  rackQuote: 0,
};

const domesticFlightsOperationsSlice = createSlice({
  name: 'domesticFlightsOperations',
  initialState,
  reducers: {
    updateAdditionalAmount: (state, action) => {
      addLog('Reducer', 'updateAdditionalAmount called', { actionPayload: action.payload });
      state.additionalAmount = action.payload;
      addLog('Reducer', 'updateAdditionalAmount updated state', { updatedState: { ...state } });
    },

    calculateQuotes: (state) => {
      addLog('Reducer', 'calculateQuotes called', { stateBefore: { ...state } });
      const rate = state.rates[0] || {};
      state.netQuote =
        state.additionalAmount +
        parseFloat(rate.adult_net || 0) +
        parseFloat(rate.child_net || 0);
      state.rackQuote =
        state.additionalAmount +
        parseFloat(rate.adult_rack || 0) +
        parseFloat(rate.child_rack || 0);
      addLog('Reducer', 'calculateQuotes updated state', { updatedState: { ...state } });
    },

    resetRates: (state) => {
      addLog('Reducer', 'resetRates called', { stateBefore: { ...state } });
      state.rates = [];
      state.errorRates = null;
      state.selectedPricingId = null;
      state.loadingRates = false;
      addLog('Reducer', 'resetRates updated state', { updatedState: { ...state } });
    },

    resetPolicy: (state) => {
      addLog('Reducer', 'resetPolicy called', { stateBefore: { ...state } });
      state.policy = null;
      state.errorPolicy = null;
      state.loadingPolicy = false;
      addLog('Reducer', 'resetPolicy updated state', { updatedState: { ...state } });
    },

    resetQuotes: (state) => {
      addLog('Reducer', 'resetQuotes called', { stateBefore: { ...state } });
      state.netQuote = 0;
      state.rackQuote = 0;
      state.additionalAmount = 0;
      addLog('Reducer', 'resetQuotes updated state', { updatedState: { ...state } });
    },

    fetchRatesFulfilled: (state, action) => {
      addLog('Reducer', 'fetchRates.fulfilled called', {
        actionPayload: action.payload,
        stateBefore: { ...state },
      });

      if (!Array.isArray(action.payload)) {
        addLog('Reducer', 'Invalid payload detected: Not an array', {
          payload: action.payload,
        });
        state.errorRates = 'Invalid payload format for rates';
        return;
      }

      const updatedRates = action.payload.map((rate) => ({
        ...rate,
        transformed: true, // Add a marker for debugging
      }));

      addLog('Reducer', 'Transforming rates into updated state', { updatedRates });

      state.rates = updatedRates;
      state.selectedPricingId = updatedRates[0]?.id || null;
      state.loadingRates = false;
      state.errorRates = null;

      addLog('Reducer', 'fetchRates.fulfilled updated state', { updatedState: { ...state } });
    },

    fetchRatesRejected: (state, action) => {
      addLog('Reducer', 'fetchRates.rejected called', {
        error: action.payload,
        stateBefore: { ...state },
      });
      state.loadingRates = false;
      state.errorRates = action.payload || 'Failed to fetch rates.';
      addLog('Reducer', 'fetchRates.rejected updated state', { updatedState: { ...state } });
    },

    fetchRatesPending: (state) => {
      addLog('Reducer', 'fetchRates.pending called', { stateBefore: { ...state } });
      state.loadingRates = true;
      state.errorRates = null;
      addLog('Reducer', 'fetchRates.pending updated state', { updatedState: { ...state } });
    },

    fetchPolicyFulfilled: (state, action) => {
      addLog('Reducer', 'fetchPolicy.fulfilled called', {
        actionPayload: action.payload,
        stateBefore: { ...state },
      });
      state.loadingPolicy = false;
      state.policy = action.payload.policy || 'No policy available.';
      addLog('Reducer', 'fetchPolicy.fulfilled updated state', { updatedState: { ...state } });
    },

    fetchPolicyRejected: (state, action) => {
      addLog('Reducer', 'fetchPolicy.rejected called', {
        error: action.payload,
        stateBefore: { ...state },
      });
      state.loadingPolicy = false;
      state.errorPolicy = action.payload || 'Failed to fetch policy.';
      addLog('Reducer', 'fetchPolicy.rejected updated state', { updatedState: { ...state } });
    },

    fetchPolicyPending: (state) => {
      addLog('Reducer', 'fetchPolicy.pending called', { stateBefore: { ...state } });
      state.loadingPolicy = true;
      state.errorPolicy = null;
      addLog('Reducer', 'fetchPolicy.pending updated state', { updatedState: { ...state } });
    },
  },
});

export const {
  updateAdditionalAmount,
  calculateQuotes,
  resetRates,
  resetPolicy,
  resetQuotes,
  fetchRatesFulfilled,
  fetchRatesRejected,
  fetchRatesPending,
  fetchPolicyFulfilled,
  fetchPolicyRejected,
  fetchPolicyPending,
} = domesticFlightsOperationsSlice.actions;

export default domesticFlightsOperationsSlice.reducer;