// File: src/components/Transfers/TransfersFrontpage.js

import React from 'react';
import TransfersSearchBar from './TransfersSearchBar';
import RateDisplay from './RateDisplay';
import { TransfersProvider } from './TransfersContext';
import styles from 'styles/Transfers.module.css';

const TransfersFrontpage = () => {
  return (
    <TransfersProvider>
      <div className={styles.transfersFrontpage}>
        <div className={styles.transfersSearchBar}>
          <TransfersSearchBar />
        </div>
        <RateDisplay />
      </div>
    </TransfersProvider>
  );
};

export default TransfersFrontpage;