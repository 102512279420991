// File: src/redux/slices/toursOperationsSlice.js
// Purpose: Manages state for rates, policies, and quotes, focusing exclusively on state updates.
// Files it interacts with:
//   - src/redux/middleware/toursMiddleware.js: Middleware dispatches actions to update state.
//   - src/services/tours_api.js: Provides raw data for rates and policies.
//   - src/services/toursTransformers.js: Transforms API data before middleware dispatches actions.
//   - src/utils/toursQuoteCalculator.js: Calculates quotes based on rates and user input.

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTourRatesByTourNameId, fetchPolicyTextByProviderId } from 'services/tours_api';
import { calculateTourQuotes } from 'utils/toursQuoteCalculator';
import { transformRates } from 'services/toursTransformers';

// Initial state
const initialState = {
    rates: [],
    policy: null,
    loadingRates: false,
    loadingPolicy: false,
    errorRates: null,
    errorPolicy: null,
    selectedProviderId: null,
    selectedProviderName: null,
    regularAdults: 0,
    regularChildren: 0,
    privateAdults: 0,
    privateChildren: 0,
    additionalAmount: 0,
    netQuote: 0,
    rackQuote: 0,
};

// Async actions
export const fetchRates = createAsyncThunk('toursOperations/fetchRates', async (tourNameId) => {
    const rawRates = await fetchTourRatesByTourNameId(tourNameId);
    return transformRates(rawRates);
});

export const fetchPolicy = createAsyncThunk('toursOperations/fetchPolicy', async (providerId) => {
    const rawPolicy = await fetchPolicyTextByProviderId(providerId);
    return rawPolicy.policy_text || 'Policy text unavailable';
});

// Slice definition
const toursOperationsSlice = createSlice({
    name: 'toursOperations',
    initialState,
    reducers: {
        updateRegularAdults: (state, action) => {
            state.regularAdults = action.payload;
        },
        updateRegularChildren: (state, action) => {
            state.regularChildren = action.payload;
        },
        updatePrivateAdults: (state, action) => {
            state.privateAdults = action.payload;
        },
        updatePrivateChildren: (state, action) => {
            state.privateChildren = action.payload;
        },
        updateAdditionalAmount: (state, action) => {
            state.additionalAmount = action.payload;
        },
        calculateQuotes: (state) => {
            const { netQuote, rackQuote } = calculateTourQuotes({
                regularAdults: state.regularAdults,
                regularChildren: state.regularChildren,
                privateAdults: state.privateAdults,
                privateChildren: state.privateChildren,
                additionalAmount: state.additionalAmount,
                rates: state.rates[0] || {},
            });
            state.netQuote = netQuote;
            state.rackQuote = rackQuote;
        },
        setSelectedProviderId: (state, action) => {
            state.selectedProviderId = action.payload;
        },
        setSelectedProviderName: (state, action) => {
            state.selectedProviderName = action.payload;
        },
        resetRates: (state) => {
            state.rates = [];
        },
        resetQuotes: (state) => {
            state.netQuote = 0;
            state.rackQuote = 0;
            state.regularAdults = 0;
            state.regularChildren = 0;
            state.privateAdults = 0;
            state.privateChildren = 0;
            state.additionalAmount = 0;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRates.pending, (state) => {
                state.loadingRates = true;
                state.errorRates = null;
            })
            .addCase(fetchRates.fulfilled, (state, action) => {
                state.loadingRates = false;
                state.rates = action.payload;
                if (state.rates.length > 0) {
                    const { providerId, providerName } = state.rates[0];
                    state.selectedProviderId = providerId || null;
                    state.selectedProviderName = providerName || 'Unknown';
                }
            })
            .addCase(fetchRates.rejected, (state, action) => {
                state.loadingRates = false;
                state.errorRates = action.error.message;
            })
            .addCase(fetchPolicy.pending, (state) => {
                state.loadingPolicy = true;
                state.errorPolicy = null;
            })
            .addCase(fetchPolicy.fulfilled, (state, action) => {
                state.loadingPolicy = false;
                state.policy = action.payload;
            })
            .addCase(fetchPolicy.rejected, (state, action) => {
                state.loadingPolicy = false;
                state.errorPolicy = action.error.message;
            });
    },
});

export const {
    updateRegularAdults,
    updateRegularChildren,
    updatePrivateAdults,
    updatePrivateChildren,
    updateAdditionalAmount,
    calculateQuotes,
    setSelectedProviderId,
    setSelectedProviderName,
    resetRates,
    resetQuotes,
} = toursOperationsSlice.actions;

export default toursOperationsSlice.reducer;