// File: src/redux/slices/domesticFlightsDataSlice.js
// Purpose: Manages state for dropdowns, selections, and data in the Domestic Flights feature.

import { createSlice } from '@reduxjs/toolkit';
import { addLog } from '../../components/Dashboard/DomesticFlights/LogViewer'; // Logging utility

const initialState = {
  selectedDate: null,
  pickupLocations: [],
  dropoffLocations: [],
  loadingPickupLocations: false,
  loadingDropoffLocations: false,
  errorPickupLocations: null,
  errorDropoffLocations: null,
  selectedPickupLocationId: null,
  selectedDropoffLocationId: null,
};

const domesticFlightsDataSlice = createSlice({
  name: 'domesticFlightsData',
  initialState,
  reducers: {
    setSelectedDate: (state, action) => {
      addLog('Reducer', 'setSelectedDate called', { selectedDate: action.payload });
      state.selectedDate = action.payload;
      state.pickupLocations = []; // Clear dependent state when date changes
      state.dropoffLocations = [];
      state.selectedPickupLocationId = null;
      state.selectedDropoffLocationId = null;
    },
    setSelectedPickupLocationId: (state, action) => {
      addLog('Reducer', 'setSelectedPickupLocationId called', { selectedPickupLocationId: action.payload });
      state.selectedPickupLocationId = action.payload;
      state.dropoffLocations = []; // Clear dependent state when pickup location changes
      state.selectedDropoffLocationId = null;
    },
    setSelectedDropoffLocationId: (state, action) => {
      addLog('Reducer', 'setSelectedDropoffLocationId called', { selectedDropoffLocationId: action.payload });
      state.selectedDropoffLocationId = action.payload;
    },
    clearPickupLocations: (state) => {
      addLog('Reducer', 'clearPickupLocations called');
      state.pickupLocations = [];
      state.selectedPickupLocationId = null;
    },
    clearDropoffLocations: (state) => {
      addLog('Reducer', 'clearDropoffLocations called');
      state.dropoffLocations = [];
      state.selectedDropoffLocationId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('domesticFlightsData/fetchPickupLocations/pending', (state) => {
        addLog('Reducer', 'fetchPickupLocations.pending called');
        state.loadingPickupLocations = true;
        state.errorPickupLocations = null;
      })
      .addCase('domesticFlightsData/fetchPickupLocations/fulfilled', (state, action) => {
        addLog('Reducer', 'fetchPickupLocations.fulfilled called', { payload: action.payload });
        state.pickupLocations = action.payload; // Update state with fetched data
        state.loadingPickupLocations = false;
        state.errorPickupLocations = null;
      })
      .addCase('domesticFlightsData/fetchPickupLocations/rejected', (state, action) => {
        addLog('Reducer', 'fetchPickupLocations.rejected called', { error: action.payload });
        state.loadingPickupLocations = false;
        state.errorPickupLocations = action.payload || 'Failed to fetch pickup locations.';
      })
      .addCase('domesticFlightsData/fetchDropoffLocations/pending', (state) => {
        addLog('Reducer', 'fetchDropoffLocations.pending called');
        state.loadingDropoffLocations = true;
        state.errorDropoffLocations = null;
      })
      .addCase('domesticFlightsData/fetchDropoffLocations/fulfilled', (state, action) => {
        addLog('Reducer', 'fetchDropoffLocations.fulfilled called', { payload: action.payload });
        state.dropoffLocations = action.payload; // Update state with fetched data
        state.loadingDropoffLocations = false;
        state.errorDropoffLocations = null;
      })
      .addCase('domesticFlightsData/fetchDropoffLocations/rejected', (state, action) => {
        addLog('Reducer', 'fetchDropoffLocations.rejected called', { error: action.payload });
        state.loadingDropoffLocations = false;
        state.errorDropoffLocations = action.payload || 'Failed to fetch dropoff locations.';
      });
  },
});

export const {
  setSelectedDate,
  setSelectedPickupLocationId,
  setSelectedDropoffLocationId,
  clearPickupLocations,
  clearDropoffLocations,
} = domesticFlightsDataSlice.actions;

export default domesticFlightsDataSlice.reducer;