// File: src/redux/slices/toursDataSlice.js
// Purpose: Manages state for years, regions, and activities, ensuring clean transitions between regions.
// Files it interacts with:
//   - src/redux/middleware/toursMiddleware.js: Handles side effects for region and activity changes.
//   - src/services/tours_api.js: Provides raw data for years, regions, and activities.
//   - src/services/toursTransformers.js: Transforms API data.

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAvailableYears, fetchRegionsByYearId, fetchToursByRegionId } from 'services/tours_api';
import { transformYears, transformRegions, transformActivities } from 'services/toursTransformers';

// Initial state
const initialState = {
    years: [],
    regions: [],
    activities: [],
    loadingYears: false,
    loadingRegions: false,
    loadingActivities: false,
    errorYears: null,
    errorRegions: null,
    errorActivities: null,
    selectedYearId: null,
    selectedRegionId: null,
    selectedActivityId: null,
};

// Async actions
export const fetchYears = createAsyncThunk('toursData/fetchYears', async () => {
    const rawYears = await fetchAvailableYears();
    return transformYears(rawYears);
});

export const fetchRegions = createAsyncThunk('toursData/fetchRegions', async (yearId) => {
    const rawRegions = await fetchRegionsByYearId(yearId);
    return transformRegions(rawRegions);
});

export const fetchActivities = createAsyncThunk('toursData/fetchActivities', async (regionId) => {
    const rawActivities = await fetchToursByRegionId(regionId);
    return transformActivities(rawActivities);
});

// Slice definition
const toursDataSlice = createSlice({
    name: 'toursData',
    initialState,
    reducers: {
        setSelectedYearId: (state, action) => {
            state.selectedYearId = action.payload;
        },
        setSelectedRegionId: (state, action) => {
            if (state.selectedRegionId !== action.payload) {
                state.activities = []; // Clear activities when region changes
                state.selectedActivityId = null; // Clear selected activity
            }
            state.selectedRegionId = action.payload;
        },
        setSelectedActivityId: (state, action) => {
            state.selectedActivityId = action.payload;
        },
        clearRegions: (state) => {
            state.regions = [];
            state.selectedRegionId = null;
        },
        clearActivities: (state) => {
            state.activities = [];
            state.selectedActivityId = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchYears.pending, (state) => {
                state.loadingYears = true;
                state.errorYears = null;
            })
            .addCase(fetchYears.fulfilled, (state, action) => {
                state.loadingYears = false;
                state.years = action.payload;
            })
            .addCase(fetchYears.rejected, (state, action) => {
                state.loadingYears = false;
                state.errorYears = action.error.message;
            })
            .addCase(fetchRegions.pending, (state) => {
                state.loadingRegions = true;
                state.errorRegions = null;
            })
            .addCase(fetchRegions.fulfilled, (state, action) => {
                state.loadingRegions = false;
                state.regions = action.payload;
            })
            .addCase(fetchRegions.rejected, (state, action) => {
                state.loadingRegions = false;
                state.errorRegions = action.error.message;
            })
            .addCase(fetchActivities.pending, (state) => {
                state.loadingActivities = true;
                state.errorActivities = null;
            })
            .addCase(fetchActivities.fulfilled, (state, action) => {
                state.loadingActivities = false;
                state.activities = action.payload;
            })
            .addCase(fetchActivities.rejected, (state, action) => {
                state.loadingActivities = false;
                state.errorActivities = action.error.message;
            });
    },
});

export const {
    setSelectedYearId,
    setSelectedRegionId,
    setSelectedActivityId,
    clearRegions,
    clearActivities,
} = toursDataSlice.actions;

export default toursDataSlice.reducer;