// File: src/services/tours_api.js
// Purpose: Handles all API requests for the Tours feature, returning raw data as provided by the backend.
// Files it interacts with:
//   - src/redux/middleware/toursMiddleware.js: Middleware uses this file to fetch data.
//   - src/services/toursTransformers.js: Transformers shape API responses after fetching.

import axios from './axios';

// Fetches available years for tours
export const fetchAvailableYears = async () => {
    const response = await axios.get('/tours/years/');
    return response.data.results; // Raw data returned for transformation elsewhere
};

// Fetches regions by a specific year ID
export const fetchRegionsByYearId = async (yearId) => {
    const response = await axios.get(`/tours/years/${yearId}/regions/`);
    return response.data.results; // Raw data returned
};

// Fetches tours by a specific region ID
export const fetchToursByRegionId = async (regionId) => {
    const response = await axios.get(`/tours/regions/${regionId}/tours/`);
    return response.data.results; // Raw data returned
};

// Fetches tour rates by a specific tour name ID
export const fetchTourRatesByTourNameId = async (tourNameId) => {
    const response = await axios.get(`/tours/tours/${tourNameId}/rates/`);
    return response.data.results; // Raw data returned
};

// Fetches policy text by a specific provider ID
export const fetchPolicyTextByProviderId = async (providerId) => {
    const response = await axios.get(`/tours/policies/${providerId}/`);
    return response.data; // Entire raw response returned for downstream processing
};

export default {
    fetchAvailableYears,
    fetchRegionsByYearId,
    fetchToursByRegionId,
    fetchTourRatesByTourNameId,
    fetchPolicyTextByProviderId,
};