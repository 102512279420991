// File: src/services/hotelSearchApiService.js
// Purpose: API service for managing all backend calls related to hotel search functionality,
//          such as fetching countries, regions, hotels, room types, and rates.

import api from "./axios";
import { addLog } from "../components/Dashboard/HotelRatesSearch/LogViewer"; // Import addLog

/**
 * Fetch the list of countries.
 * @returns {Promise<Array>} - The list of countries.
 */
export const fetchCountries = async () => {
  try {
    const response = await api.get("/countries/");
    addLog("API Response", "Fetched countries", response.data.results);
    return response.data.results; // Extract the results array
  } catch (error) {
    addLog("API Error", "Failed to fetch countries", error.message);
    throw error;
  }
};

/**
 * Fetch the list of regions for a given country.
 * @param {string} countryId - The ID of the selected country.
 * @returns {Promise<Array>} - The list of regions.
 */
export const fetchRegions = async (countryId) => {
  try {
    const response = await api.get("/regions/", { params: { country: countryId } });
    addLog("API Response", "Fetched regions for country", {
      countryId,
      regions: response.data.results,
    });
    return response.data.results; // Extract the results array
  } catch (error) {
    addLog("API Error", "Failed to fetch regions", error.message);
    throw error;
  }
};

/**
 * Fetch the list of hotels for a given region.
 * @param {string} regionId - The ID of the selected region.
 * @returns {Promise<Array>} - The list of hotels.
 */
export const fetchHotels = async (regionId) => {
  try {
    const response = await api.get("/hotels/", { params: { region: regionId } });
    addLog("API Response", "Fetched hotels for region", {
      regionId,
      hotels: response.data.results,
    });
    return response.data.results; // Extract the results array
  } catch (error) {
    addLog("API Error", "Failed to fetch hotels", error.message);
    throw error;
  }
};

/**
 * Fetch the list of room types for a given hotel.
 * @param {string} hotelId - The ID of the selected hotel.
 * @returns {Promise<Array>} - The list of room types.
 */
export const fetchRoomTypes = async (hotelId) => {
  try {
    const response = await api.get("/roomtypes/", { params: { hotel_id: hotelId } });
    addLog("API Response", "Fetched room types for hotel", {
      hotelId,
      roomTypes: response.data.results,
    });
    return response.data.results; // Extract the results array
  } catch (error) {
    addLog("API Error", "Failed to fetch room types", error.message);
    throw error;
  }
};

/**
 * Fetch nightly rates for a room type within a date range.
 * @param {Object} params - Query parameters.
 * @param {string} params.roomTypeId - The ID of the selected room type.
 * @param {string} params.startDate - Start date in 'YYYY-MM-DD' format.
 * @param {string} params.endDate - End date in 'YYYY-MM-DD' format.
 * @returns {Promise<Array>} - The list of rates with details.
 */
export const fetchRates = async ({ roomTypeId, startDate, endDate }) => {
  try {
    const response = await api.get("/rates/", {
      params: { room_type: roomTypeId, start_date: startDate, end_date: endDate },
    });
    addLog("API Response", "Fetched rates for room type", {
      roomTypeId,
      startDate,
      endDate,
      rates: response.data.results,
    });
    return response.data.results; // Extract the results array
  } catch (error) {
    addLog("API Error", "Failed to fetch rates", error.message);
    throw error;
  }
};

/**
 * Fetch the available date range for rates in the database for a specific hotel.
 * @param {string} hotelId - The ID of the selected hotel.
 * @returns {Promise<Object>} - An object with `firstAvailableDate` and `lastAvailableDate`.
 */
export const fetchAvailableDates = async (hotelId) => {
  if (!hotelId) throw new Error("Hotel ID is required.");
  try {
    const response = await api.get("/available-dates/", {
      params: { hotel_id: hotelId },
    });
    addLog("API Response", "Fetched available date range", response.data);
    return {
      firstAvailableDate: response.data.first_available_date,
      lastAvailableDate: response.data.last_available_date,
    };
  } catch (error) {
    addLog("API Error", "Failed to fetch available date range", error.message);
    throw error;
  }
};

/**
 * Search for hotels by name or region.
 * @param {Object} params - Query parameters.
 * @param {string} params.type - The type of search, either "hotel" or "region".
 * @param {string} params.query - The search query (e.g., hotel name or region name).
 * @returns {Promise<Array>} - The list of hotels or regions matching the query.
 */
export const searchHotelsOrRegions = async ({ type, query }) => {
  if (!type || !query) throw new Error("Both 'type' and 'query' parameters are required.");
  try {
    const response = await api.get("/hotelsearch/", {
      params: { type, query },
    });
    addLog("API Response", "Searched hotels or regions", {
      type,
      query,
      results: response.data,
    });
    return response.data; // Return the search results
  } catch (error) {
    addLog("API Error", `Failed to search ${type}`, error.message);
    throw error;
  }
};