// File: src/components/Dashboard/DomesticFlights/DomesticFlightsRatesSection.js
// Purpose: Displays the rates for selected pickup and dropoff locations based on the selected date.
// Related files:
//   - DomesticFlightsRatesTable.js: Displays the rates in a table format.
//   - ../../../redux/slices/domesticFlightsOperationsSlice.js: Manages state for rates.
//   - ../../../redux/slices/domesticFlightsSelectors.js: Provides selectors for rates and related errors.

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  resetRates,
} from '../../../redux/slices/domesticFlightsOperationsSlice';
import {
  selectRates,
  selectIsLoadingRates,
  selectRatesError,
  selectSelectedDate,
  selectSelectedPickupLocationId,
  selectSelectedDropoffLocationId,
} from '../../../redux/slices/domesticFlightsSelectors';
import { addLog } from './LogViewer'; // Import addLog for debugging
import DomesticFlightsRatesTable from './DomesticFlightsRatesTable';
import styles from './domesticFlights.module.css';

const DomesticFlightsRatesSection = () => {
  const dispatch = useDispatch();
  const rates = useSelector(selectRates);
  const loadingRates = useSelector(selectIsLoadingRates);
  const errorRates = useSelector(selectRatesError);
  const selectedDate = useSelector(selectSelectedDate);
  const selectedPickupLocationId = useSelector(selectSelectedPickupLocationId);
  const selectedDropoffLocationId = useSelector(selectSelectedDropoffLocationId);

  useEffect(() => {
    if (selectedDate && selectedPickupLocationId && selectedDropoffLocationId) {
      addLog('Component', 'Dispatching fetchRates action via middleware', {
        selectedDate,
        selectedPickupLocationId,
        selectedDropoffLocationId,
      });
      dispatch({
        type: 'domesticFlights/fetchRates',
        payload: {
          date: selectedDate,
          pickupLocationId: selectedPickupLocationId,
          dropoffLocationId: selectedDropoffLocationId,
        },
      });
    } else {
      addLog('Component', 'Dispatching resetRates action', {});
      dispatch(resetRates());
    }
  }, [selectedDate, selectedPickupLocationId, selectedDropoffLocationId, dispatch]);

  if (loadingRates) {
    return <div className={styles.ratesContainer}>Loading rates...</div>;
  }

  if (errorRates) {
    return (
      <div className={styles.ratesContainer}>
        <p className={styles.errorMessage}>Failed to fetch rates: {errorRates}</p>
      </div>
    );
  }

  if (!rates || rates.length === 0) {
    return <div className={styles.noRatesMessage}>Select a route for rates and quotes.</div>;
  }

  return (
    <div className={styles.ratesContainer}>
      <DomesticFlightsRatesTable rates={rates} />
    </div>
  );
};

export default DomesticFlightsRatesSection;