// File: src/components/Layouts/NavigationBar.js
// Purpose: Displays the navigation bar with links to different dashboard sections.

import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import logo from "assets/dtalogo.png";
import etaLogo from "assets/etalogo.png";  // Path to etalogo
import styles from "../../styles/NavigationBar.module.css";

const NavigationBar = () => {
  const location = useLocation();

  const menuItems = [
    {
      label: <Link to="/dashboard/hotelsearch">Hotels</Link>,
      key: "/dashboard/hotelsearch",
    },
    {
      label: <Link to="/dashboard/transfers">Transfers</Link>,
      key: "/dashboard/transfers",
    },
    {
      label: <Link to="/dashboard/tours">Tours</Link>,
      key: "/dashboard/tours",
    },
    {
      label: <Link to="/dashboard/rentalcars">Rental Cars</Link>,
      key: "/dashboard/rentalcars",
    },
    {
      label: <Link to="/dashboard/domesticflights">Domestic Flights</Link>,
      key: "/dashboard/domesticflights",
    },
  ];

  return (
    <div className={styles.navbarContainer}>
      <div className={styles.navbarLogo}>
        <img src={logo} alt="Logo" />
        <img src={etaLogo} alt="ETA Logo" className={styles.navbarsisterlogo} />
      </div>
      <Menu
        mode="horizontal"
        selectedKeys={[location.pathname]}
        className={styles.menu}
        items={menuItems}
      />
    </div>
  );
};

export default NavigationBar;