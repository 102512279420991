// File: src/components/Dashboard/DomesticFlights/DomesticFlightsDatePicker.js
// Purpose: Provides a date picker for selecting the flight date, triggering state updates and API calls for fetching pickup locations.

import React from 'react';
import { DatePicker, Tooltip } from 'antd'; // Import Tooltip
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedDate } from '../../../redux/slices/domesticFlightsDataSlice';
import { selectSelectedDate } from '../../../redux/slices/domesticFlightsSelectors';
import styles from './domesticFlights.module.css';
import dayjs from 'dayjs';

const DomesticFlightsDatePicker = () => {
  const dispatch = useDispatch();
  const selectedDate = useSelector(selectSelectedDate);

  const handleDateChange = (date) => {
    const formattedDate = date ? date.format('YYYY-MM-DD') : null;

    // Dispatch selected date to the Redux store
    dispatch(setSelectedDate(formattedDate));
  };

  // Disable dates after 30 April 2025
  const disabledDate = (current) => {
    const maxDate = dayjs('2025-04-30'); // Define the maximum allowed date
    return current && current.isAfter(maxDate, 'day'); // Disable if the date is after maxDate
  };

  return (
    <div className={styles.datePickerWrapper}>
      <Tooltip title="Flight data is available only through April 30, 2025.">
        <DatePicker
          className={styles.datePicker}
          value={selectedDate ? dayjs(selectedDate, 'YYYY-MM-DD') : null}
          onChange={handleDateChange}
          placeholder="Select Date"
          format="DD-MMM-YYYY" // Display format in the input box
          showToday={false}
          disabledDate={disabledDate} // Pass the disabledDate function here
        />
      </Tooltip>
    </div>
  );
};

export default DomesticFlightsDatePicker;