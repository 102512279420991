// File: src/redux/store.js
// Purpose: Configures the Redux store with reducers and middleware for the project, including hotels, tours, and domestic flights functionality.

import { configureStore } from '@reduxjs/toolkit';
import hotelLocationReducer from './slices/hotelLocationSlice';
import hotelRatesReducer from './slices/hotelRatesSlice';
import occupancyReducer from './slices/occupancySlice';
import toursDataReducer from './slices/toursDataSlice';
import toursOperationsReducer from './slices/toursOperationsSlice';
import domesticFlightsDataReducer from './slices/domesticFlightsDataSlice'; // Domestic Flights Data Slice
import domesticFlightsOperationsReducer from './slices/domesticFlightsOperationsSlice'; // Domestic Flights Operations Slice
import toursMiddleware from './middleware/toursMiddleware';
import ratesMiddleware from './middleware/ratesMiddleware';
import searchBarMiddleware from './middleware/searchBarMiddleware';
import domesticFlightsDataListenerMiddleware from './middleware/domesticFlightsDataMiddleware'; // Updated to Listener Middleware
import domesticFlightsOperationsListenerMiddleware from './middleware/domesticFlightsOperationsMiddleware'; // Updated to Listener Middleware

export const store = configureStore({
    reducer: {
        hotelLocation: hotelLocationReducer,
        hotelRates: hotelRatesReducer,
        occupancy: occupancyReducer,
        toursData: toursDataReducer,
        toursOperations: toursOperationsReducer,
        domesticFlightsData: domesticFlightsDataReducer, // Added
        domesticFlightsOperations: domesticFlightsOperationsReducer, // Added
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            toursMiddleware,
            ratesMiddleware,
            searchBarMiddleware,
            domesticFlightsDataListenerMiddleware.middleware, // Access .middleware property
            domesticFlightsOperationsListenerMiddleware.middleware // Access .middleware property
        ),
    devTools: process.env.NODE_ENV !== 'production', // Enables Redux DevTools in development
});

export default store;