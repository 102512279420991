// File: src/redux/slices/hotelRatesSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchRates, fetchAvailableDates } from "../../services/hotelSearchApiService";
import { mapRatesToDaily, calculateTotals } from "../../utils/hotelRateCalculations";

// Thunk: Fetch rates from the API
export const fetchRatesAsync = createAsyncThunk(
  "hotelRates/fetchRates",
  async ({ roomTypeId, startDate, endDate }, { rejectWithValue }) => {
    try {
      const rates = await fetchRates({ roomTypeId, startDate, endDate });
      if (!Array.isArray(rates)) throw new Error("Invalid rates format.");
      return rates;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunk: Fetch available dates from the API
export const fetchAvailableDatesAsync = createAsyncThunk(
  "hotelRates/fetchAvailableDates",
  async (hotelId, { rejectWithValue }) => {
    try {
      const response = await fetchAvailableDates(hotelId);
      return {
        firstAvailableDate: new Date(response.firstAvailableDate).toLocaleDateString(),
        lastAvailableDate: new Date(response.lastAvailableDate).toLocaleDateString(),
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const hotelRatesSlice = createSlice({
  name: "hotelRates",
  initialState: {
    rates: [],
    dailyRates: [],
    totals: {
      totalNet: 0,
      totalRack: 0,
    },
    availability: null,
    loading: false,
    error: null,
    selectedDates: { checkIn: null, checkOut: null },
  },
  reducers: {
    setDates(state, action) {
      state.selectedDates = action.payload || { checkIn: null, checkOut: null };
    },
    resetRates(state) {
      state.rates = [];
      state.dailyRates = [];
      state.totals = {
        totalNet: 0,
        totalRack: 0,
      };
      state.availability = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRatesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRatesAsync.fulfilled, (state, action) => {
        const rates = action.payload;
        state.dailyRates = mapRatesToDaily(rates, state.selectedDates.checkIn, state.selectedDates.checkOut);
        state.totals = calculateTotals(state.dailyRates);
        state.loading = false;
      })
      .addCase(fetchRatesAsync.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchAvailableDatesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAvailableDatesAsync.fulfilled, (state, action) => {
        state.availability = action.payload;
        state.loading = false;
      })
      .addCase(fetchAvailableDatesAsync.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

// Selectors
export const selectDailyRates = (state) => state.hotelRates.dailyRates;
export const selectTotals = (state) => state.hotelRates.totals;
export const selectAvailability = (state) => state.hotelRates.availability;
export const selectLoading = (state) => state.hotelRates.loading;
export const selectError = (state) => state.hotelRates.error;

// Actions
export const { setDates, resetRates } = hotelRatesSlice.actions;

export default hotelRatesSlice.reducer;