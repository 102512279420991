// File: src/components/Dashboard/DomesticFlights/LogViewer.js
// Purpose: Provides an in-memory log viewer for debugging and monitoring application events.

import React, { useState, useEffect } from "react";

const logs = []; // In-memory log storage

/**
 * Function to add logs to the in-memory log storage.
 * @param {string} type - The category/type of the log (e.g., API, Redux, Component).
 * @param {string} message - The message describing the event.
 * @param {Object} data - Additional data to include in the log.
 */
export const addLog = (type, message, data) => {
  const timestamp = new Date().toISOString();
  logs.push(`[${timestamp}] [${type}] ${message}: ${JSON.stringify(data)}`);
};

const LogViewer = () => {
  const [logMessages, setLogMessages] = useState([]);
  const [filter, setFilter] = useState("All");

  const logTypes = ["All", "Component", "Redux", "API", "Middleware"];

  // Update the log viewer every 2 seconds to reflect new logs
  useEffect(() => {
    const interval = setInterval(() => {
      const filteredLogs =
        filter === "All" ? logs : logs.filter((log) => log.includes(`[${filter}]`));
      setLogMessages([...filteredLogs]);
    }, 2000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [filter]);

  // Function to clear logs
  const clearLogs = () => {
    logs.length = 0; // Clear in-memory logs
    setLogMessages([]); // Clear component state
    alert("Logs cleared successfully!");
  };

  // Function to copy logs to the clipboard
  const copyLogs = () => {
    const logsText = logMessages.join("\n");
    navigator.clipboard.writeText(logsText).then(
      () => alert("Logs copied to clipboard!"),
      (err) => alert("Failed to copy logs: " + err)
    );
  };

  // Inline styles for the LogViewer
  const styles = {
    container: {
      marginTop: "20px",
      padding: "10px",
      border: "1px solid #ddd",
      borderRadius: "5px",
      backgroundColor: "#f9f9f9",
      maxHeight: "400px",
      overflowY: "auto",
      fontFamily: "monospace",
      fontSize: "12px",
      maxWidth: "100%", // Add this line to ensure the container has a limited width
      wordWrap: "break-word", // Ensure long words are wrapped inside the container
      whiteSpace: "pre-wrap", // Preserve line breaks and formatting
    },
    logEntry: {
      marginBottom: "10px",
      borderBottom: "1px solid #eee",
      paddingBottom: "5px",
      color: "#333",
      whiteSpace: "pre-wrap", // Ensure the log text respects line breaks
      wordWrap: "break-word", // Ensure long lines break appropriately
    },
    dropdown: {
      marginBottom: "10px",
      padding: "5px",
      borderRadius: "3px",
      border: "1px solid #ddd",
      backgroundColor: "#f9f9f9",
    },
    button: {
      margin: "5px 5px 10px 0",
      padding: "5px 10px",
      borderRadius: "3px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      cursor: "pointer",
    },
    buttonSecondary: {
      margin: "5px 0 10px 0",
      padding: "5px 10px",
      borderRadius: "3px",
      backgroundColor: "#6c757d",
      color: "#fff",
      border: "none",
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.container}>
      <h3>Application Logs</h3>
      <select
        style={styles.dropdown}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      >
        {logTypes.map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </select>
      <button style={styles.button} onClick={copyLogs}>
        Copy Logs
      </button>
      <button style={styles.buttonSecondary} onClick={clearLogs}>
        Clear Logs
      </button>
      <div>
        {logMessages.length > 0 ? (
          logMessages.map((log, index) => (
            <p key={index} style={styles.logEntry}>
              {log}
            </p>
          ))
        ) : (
          <p>No logs available</p>
        )}
      </div>
    </div>
  );
};

export default LogViewer;