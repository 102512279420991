// File: src/components/Transfers/DropoffLocationDropdown.js
// Purpose: Dropdown component for selecting drop-off locations based on the selected pickup location.
// Related Files: TransfersContext.js, Transfers.module.css, transfers_api.js

import React, { useState, useEffect } from 'react';
import Select from 'antd/lib/select';
import { fetchDropOffLocationsByPickup } from 'services/transfers_api';
import { useTransfers } from './TransfersContext';
import styles from 'styles/Transfers.module.css';

const { Option } = Select;

const DropoffLocationDropdown = () => {
    const { pickupId, handleDropOffChange } = useTransfers();
    const [dropOffLocations, setDropOffLocations] = useState([]);
    const [selectedDropOff, setSelectedDropOff] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getDropOffLocations = async () => {
            if (pickupId) {
                setLoading(true);
                try {
                    const response = await fetchDropOffLocationsByPickup(pickupId);
                    const transformedLocations = response?.data?.results || [];
                    setDropOffLocations(transformedLocations);
                    setSelectedDropOff(null);
                } catch (error) {
                    setDropOffLocations([]);
                } finally {
                    setLoading(false);
                }
            } else {
                setDropOffLocations([]);
                setSelectedDropOff(null);
            }
        };

        getDropOffLocations();
    }, [pickupId]);

    const handleDropOffSelect = (value, option) => {
        setSelectedDropOff(option);
        handleDropOffChange(value, option ? option.children : '');
    };

    return (
        <Select
            className={styles.reactSelectControl}
            value={selectedDropOff ? selectedDropOff.value : null}
            onChange={handleDropOffSelect}
            placeholder="Select a drop-off location"
            allowClear
            showSearch
            filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
            }
            notFoundContent={loading ? "Loading..." : "No data available"}
            style={{ width: '100%' }}
        >
            {dropOffLocations.map(location => (
                <Option key={location.id} value={location.id}>
                    {location.dropoff_location}
                </Option>
            ))}
        </Select>
    );
};

export default DropoffLocationDropdown;