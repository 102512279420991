// File: src/services/axios.js
// Purpose: Configures an Axios instance with interceptors for handling API requests and responses,
//          including logging via addLog for debugging.

import axios from "axios";
import { addLog } from "../components/Dashboard/HotelRatesSearch/LogViewer"; // Import addLog

if (!process.env.REACT_APP_API_BASE_URL) {
  throw new Error("REACT_APP_API_BASE_URL is not defined. Check your environment configuration.");
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Enforce use of environment variable
  withCredentials: true, // Allows cookies to be sent with requests
});

/**
 * Request Interceptor
 * - Logs outgoing requests via addLog.
 * - Attaches the Authorization token to all outgoing requests if it exists in localStorage.
 */
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    addLog("API Request", "Sending request", {
      method: config.method,
      url: config.url,
      headers: { ...config.headers, Authorization: "Bearer [REDACTED]" }, // Redact token
      data: config.data,
    });
    return config;
  },
  (error) => {
    addLog("API Error", "Request error", error.message);
    return Promise.reject(error);
  }
);

/**
 * Response Interceptor
 * - Logs API responses via addLog.
 * - Handles token refresh for 401 errors and retries the original request if successful.
 */
axiosInstance.interceptors.response.use(
  (response) => {
    addLog("API Response", "Received response", {
      url: response.config.url,
      data: response.data,
    });
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check for 401 Unauthorized and no prior retry
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refresh_token");

      if (refreshToken) {
        try {
          addLog("API Info", "Refreshing token", null);
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/auth/token/refresh/`,
            { refresh: refreshToken }
          );

          // Update tokens in localStorage
          localStorage.setItem("access_token", response.data.access);

          // Update headers for the retried request
          axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${response.data.access}`;
          originalRequest.headers["Authorization"] = `Bearer ${response.data.access}`;

          addLog("API Info", "Token refreshed successfully", null);
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          addLog("API Error", "Token refresh failed", refreshError.message);
          logoutUser();
        }
      } else {
        addLog("API Warning", "No refresh token found. Logging out.", null);
        logoutUser();
      }
    }

    addLog("API Error", "Response error", {
      url: originalRequest.url,
      message: error.message,
    });
    return Promise.reject(error);
  }
);

/**
 * Logs the user out by clearing tokens and redirecting to the login page.
 */
function logoutUser() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  window.location.href = "/login"; // Redirect to login page
}

export default axiosInstance;