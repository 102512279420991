// File: src/components/Transfers/PickupLocationDropdown.js
// Purpose: Dropdown component for selecting pickup locations based on the selected year ID.
// Related Files: TransfersContext.js, Transfers.module.css, transfers_api.js

import React, { useState, useEffect } from 'react';
import Select from 'antd/lib/select';
import { fetchPickupLocationsByYearId } from 'services/transfers_api';
import { useTransfers } from './TransfersContext';
import styles from 'styles/Transfers.module.css';

const { Option } = Select;

const PickupLocationDropdown = () => {
    const { yearId, handlePickupChange } = useTransfers();
    const [pickupLocations, setPickupLocations] = useState([]);
    const [selectedPickup, setSelectedPickup] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getPickupLocations = async () => {
            if (yearId) {
                setLoading(true);
                try {
                    const response = await fetchPickupLocationsByYearId(yearId);
                    const transformedLocations = response?.data?.results || [];
                    setPickupLocations(transformedLocations);
                } catch (error) {
                    setPickupLocations([]);
                } finally {
                    setLoading(false);
                }
            } else {
                setPickupLocations([]);
                setSelectedPickup(null);
            }
        };

        getPickupLocations();
    }, [yearId]);

    const handlePickupSelect = (value, option) => {
        setSelectedPickup(option);
        handlePickupChange(value, option ? option.children : '');
    };

    return (
        <Select
            className={styles.reactSelectControl}
            value={selectedPickup ? selectedPickup.value : null}
            onChange={handlePickupSelect}
            placeholder="Select a pickup location"
            allowClear
            showSearch
            filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
            }
            notFoundContent={loading ? "Loading options..." : "No data available"}
            style={{ width: '100%' }}
        >
            {pickupLocations.map(location => (
                <Option key={location.id} value={location.id}>
                    {location.pickup_location}
                </Option>
            ))}
        </Select>
    );
};

export default PickupLocationDropdown;