// File: src/redux/middleware/searchBarMiddleware.js
// Purpose: Updated to handle auto-selection of country and reset logic for region during reverse lookup.

import {
  fetchCountriesAsync,
  fetchRegionsAsync,
  fetchHotelsAsync,
  setSelectedCountry,
  setSelectedRegion,
} from "../slices/hotelLocationSlice";
import { addLog } from "../../components/Dashboard/HotelRatesSearch/LogViewer";

// Prevent re-initialization logs
let isSearchBarMiddlewareInitialized = false;

const searchBarMiddleware = (store) => (next) => (action) => {
  // Log middleware initialization
  if (!isSearchBarMiddlewareInitialized) {
    addLog("Middleware Init", "searchBarMiddleware initialized", null);
    isSearchBarMiddlewareInitialized = true;
  }

  // Pass the action to the next middleware or reducer
  next(action);

  // Access the current state
  const state = store.getState();
  const { countries, fetchStatus, selectedCountry, selectedRegion, hotels } =
    state.hotelLocation;

  // Handle the action to trigger fetching countries explicitly
  if (action.type === "hotelLocation/triggerCountryFetch") {
    if (countries.length === 0 && fetchStatus === "idle") {
      addLog("Middleware Info", "Fetching countries triggered by action", null);
      store.dispatch(fetchCountriesAsync());
    }
  }

  // Log every monitored action
  addLog("Middleware Action", `Processing action: ${action.type}`, action.payload);

  // Actions to monitor for further API calls
  const monitoredActions = [
    "hotelLocation/setSelectedCountry",
    "hotelLocation/setSelectedRegion",
    "hotelLocation/setSelectedHotel",
  ];

  if (monitoredActions.includes(action.type)) {
    addLog("Middleware Info", `Monitored action detected: ${action.type}`, action.payload);

    // Handle country selection to fetch regions
    if (action.type === "hotelLocation/setSelectedCountry") {
      addLog("Middleware Info", "Fetching regions for selected country", { selectedCountry });
      if (selectedCountry) {
        store.dispatch(fetchRegionsAsync(selectedCountry));
      }
    }

    // Handle region selection to fetch hotels
    if (action.type === "hotelLocation/setSelectedRegion") {
      addLog("Middleware Info", "Fetching hotels for selected region", { selectedRegion });
      if (selectedRegion) {
        store.dispatch(fetchHotelsAsync(selectedRegion));
      }
    }

    // Handle hotel selection to auto-select country and reset regions
    if (action.type === "hotelLocation/setSelectedHotel") {
      const hotel = hotels.find((h) => h.id === action.payload);

      if (hotel) {
        if (hotel.country_id) {
          store.dispatch(setSelectedCountry(hotel.country_id));
          addLog("Middleware Info", "Auto-selected country based on hotel", {
            countryId: hotel.country_id,
          });
        }

        if (hotel.region_id) {
          store.dispatch(setSelectedRegion(hotel.region_id));
          addLog("Middleware Info", "Region updated based on selected hotel", {
            regionId: hotel.region_id,
          });
        } else {
          store.dispatch(setSelectedRegion(null));
          addLog("Middleware Info", "Region cleared during reverse lookup", null);
        }
      } else {
        addLog(
          "Middleware Info",
          "No matching hotel found in state for selectedHotel action",
          action.payload
        );
      }
    }
  }
};

export default searchBarMiddleware;