// File: src/components/Dashboard/DomesticFlights/DomesticFlightsOriginDropdown.js
// Purpose: Dropdown for selecting a pickup location based on the selected date.
// Related files:
//   - ../../../redux/slices/domesticFlightsDataSlice.js: Handles state updates for pickup locations.
//   - ../../../redux/slices/domesticFlightsSelectors.js: Provides selectors for pickup locations and errors.

import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSelectedPickupLocationId,
  clearPickupLocations,
} from '../../../redux/slices/domesticFlightsDataSlice';
import {
  selectPickupLocations,
  selectIsLoadingPickupLocations,
  selectPickupLocationsError,
  selectSelectedPickupLocationId,
  selectSelectedDate,
} from '../../../redux/slices/domesticFlightsSelectors';
import { addLog } from './LogViewer'; // Import addLog for debugging
import styles from './domesticFlights.module.css';

const { Option } = Select;

const DomesticFlightsOriginDropdown = () => {
  const dispatch = useDispatch();
  const selectedDate = useSelector(selectSelectedDate);
  const pickupLocations = useSelector(selectPickupLocations);
  const selectedPickupLocationId = useSelector(selectSelectedPickupLocationId);
  const loadingPickupLocations = useSelector(selectIsLoadingPickupLocations);
  const errorPickupLocations = useSelector(selectPickupLocationsError);

  // Monitor selectedDate and reset pickup locations if it is cleared
  useEffect(() => {
    if (!selectedDate) {
      addLog('Component', 'No date selected, dispatching clearPickupLocations', {});
      dispatch(clearPickupLocations());
    } else {
      addLog('Component', 'Selected date exists, state relies on middleware for pickup locations', {
        selectedDate,
      });
    }
  }, [selectedDate, dispatch]);

  // Handle user selection of a pickup location
  const handlePickupSelect = (value) => {
    addLog('Component', 'setSelectedPickupLocationId triggered', { selectedPickupLocationId: value });
    dispatch(setSelectedPickupLocationId(value));
  };

  return (
    <div className={styles.dropdownWrapper}>
      <Select
        showSearch
        value={selectedPickupLocationId}
        onChange={handlePickupSelect}
        placeholder={
          loadingPickupLocations
            ? 'Loading pickup locations...'
            : errorPickupLocations
            ? 'Failed to load pickup locations'
            : 'Select a pickup location'
        }
        allowClear
        className={`${styles.dropdown} ${styles.originDropdown}`}
        style={{ width: '100%' }}
        notFoundContent={
          loadingPickupLocations
            ? 'Loading...'
            : errorPickupLocations
            ? errorPickupLocations
            : 'No locations available'
        }
        disabled={!selectedDate || loadingPickupLocations}
      >
        {Array.isArray(pickupLocations) &&
          pickupLocations.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
      </Select>
    </div>
  );
};

export default DomesticFlightsOriginDropdown;