// File: src/components/Dashboard/Tours/ToursQuoteGeneratorSection.js
// Purpose: Dynamically calculates and displays tour quotes based on user inputs and Redux state.
// Files it interacts with:
//   - src/redux/slices/toursOperationsSlice.js: Dispatches actions to update user inputs and trigger quote calculations.
//   - src/redux/slices/toursSelectors.js: Pulls data from Redux for rates, quotes, and user inputs.

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateRegularAdults,
    updateRegularChildren,
    updatePrivateAdults,
    updatePrivateChildren,
    updateAdditionalAmount,
    calculateQuotes,
} from '../../../redux/slices/toursOperationsSlice';
import { Button, InputNumber } from 'antd';
import styles from 'styles/Tours.module.css';
import {
    selectSelectedProviderName,
    selectSelectedActivityName,
    selectRegularAdults,
    selectRegularChildren,
    selectPrivateAdults,
    selectPrivateChildren,
    selectNetQuote,
    selectRackQuote,
    selectAdditionalAmount,
    selectRates,
} from '../../../redux/slices/toursSelectors';

const ToursQuoteGeneratorSection = () => {
    const dispatch = useDispatch();
    const [localAdditionalAmount, setLocalAdditionalAmount] = useState(0);
    const [isCopied, setIsCopied] = useState(false); // State for subtle copy feedback

    // Redux state
    const providerName = useSelector(selectSelectedProviderName);
    const activityName = useSelector(selectSelectedActivityName);
    const regularAdults = useSelector(selectRegularAdults);
    const regularChildren = useSelector(selectRegularChildren);
    const privateAdults = useSelector(selectPrivateAdults);
    const privateChildren = useSelector(selectPrivateChildren);
    const netQuote = useSelector(selectNetQuote);
    const rackQuote = useSelector(selectRackQuote);
    const additionalAmount = useSelector(selectAdditionalAmount);
    const rates = useSelector(selectRates);

    // Sync local state with Redux when additionalAmount changes
    useEffect(() => {
        setLocalAdditionalAmount(additionalAmount);
    }, [additionalAmount]);

    // Recalculate quotes whenever rates or user inputs change
    useEffect(() => {
        dispatch(calculateQuotes());
    }, [
        dispatch,
        regularAdults,
        regularChildren,
        privateAdults,
        privateChildren,
        additionalAmount,
        rates, // Include rates in the dependency array
    ]);

    const handleInputChange = (action, value) => {
        if (value < 0) value = 0; // Prevent negative values
        dispatch(action(value));
    };

    const handleAdditionalAmountChange = (value) => {
        if (value < 0) value = 0; // Prevent negative values
        setLocalAdditionalAmount(value); // Update local state immediately
        dispatch(updateAdditionalAmount(value)); // Dispatch to Redux
    };

    const handleCopyToClipboard = () => {
        const participantList = [];

        if (regularAdults > 0) {
            participantList.push(`${regularAdults} regular adult(s)`);
        }
        if (regularChildren > 0) {
            participantList.push(`${regularChildren} regular child(ren)`);
        }
        if (privateAdults > 0) {
            participantList.push(`${privateAdults} private adult(s)`);
        }
        if (privateChildren > 0) {
            participantList.push(`${privateChildren} private child(ren)`);
        }

        const participantSummary = participantList.join(' and ');

        const textToCopy = `${providerName}\t${activityName} for ${participantSummary}\t${netQuote.toFixed(
            2
        )}\t${rackQuote.toFixed(2)}`;

        navigator.clipboard.writeText(textToCopy).then(() => {
            setIsCopied(true); // Set "Copied!" state
            setTimeout(() => setIsCopied(false), 2000); // Reset to "Copy Quote" after 2 seconds
        });
    };

    return (
        <div className={styles.quoteGenerator}>
            <h3 className={styles.quoteGeneratorTitle}>Quote Generator</h3>

            {/* Input Rows */}
            <div className={styles.quoteRow}>
                <label>Regular Adults:</label>
                <InputNumber
                    min={0}
                    value={regularAdults}
                    onChange={(value) => handleInputChange(updateRegularAdults, value)}
                />
            </div>
            <div className={styles.quoteRow}>
                <label>Regular Children:</label>
                <InputNumber
                    min={0}
                    value={regularChildren}
                    onChange={(value) => handleInputChange(updateRegularChildren, value)}
                />
            </div>
            <div className={styles.quoteRow}>
                <label>Private Adults:</label>
                <InputNumber
                    min={0}
                    value={privateAdults}
                    onChange={(value) => handleInputChange(updatePrivateAdults, value)}
                />
            </div>
            <div className={styles.quoteRow}>
                <label>Private Children:</label>
                <InputNumber
                    min={0}
                    value={privateChildren}
                    onChange={(value) => handleInputChange(updatePrivateChildren, value)}
                />
            </div>
            <div className={styles.quoteRow}>
                <label>Additional Expense:</label>
                <InputNumber
                    min={0}
                    value={localAdditionalAmount} // Use local state for immediate updates
                    formatter={(value) => `$ ${value}`}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={handleAdditionalAmountChange} // Update both local and Redux state
                />
            </div>

            {/* Quote Results */}
            <div className={styles.quoteResults}>
                <table className={styles.previewTable}>
                    <tbody>
                        <tr>
                            <td><strong>Net Quote:</strong></td>
                            <td>${netQuote.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td><strong>Rack Quote:</strong></td>
                            <td>${rackQuote.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Copy Button */}
            <Button
                className={styles.copyQuoteButton}
                onClick={handleCopyToClipboard}
            >
                {isCopied ? 'Copied!' : 'Copy Quote'}
            </Button>
        </div>
    );
};

export default ToursQuoteGeneratorSection;