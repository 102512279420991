// File: src/components/Dashboard/RentalCars/RentalCarsQuoteGenerator.js
// Purpose: Generates rental car quotes based on selected dates and rates. Uses Ant Design for UI components.

import React, { useState, useEffect } from 'react';
import { useRentalCars } from 'components/Dashboard/RentalCars/RentalCarsContext';
import { Table, Typography, Button } from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'; // Import the isBetween plugin
import calculateQuote from 'utils/rentalCalculations';
import styles from 'styles/RentalCars.module.css';

// Extend dayjs with the isBetween plugin
dayjs.extend(isBetween);

const { Title } = Typography;

const RentalCarsQuoteGenerator = () => {
  const { rates, startDate, endDate } = useRentalCars();
  const [quotes, setQuotes] = useState([]);
  const [summary, setSummary] = useState('');
  const [copyButtonText, setCopyButtonText] = useState('');

  useEffect(() => {
    if (!startDate || !endDate || !rates.length) {
      setQuotes([]);
      setSummary('');
      return;
    }

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    const targetDate = new Date(
      startDateObj.getFullYear(),
      startDateObj.getMonth(),
      startDateObj.getDate()
    );
    targetDate.setHours(0, 0, 0, 0);

    const season = determineSeason(targetDate, rates[0].seasons);

    if (season && season.season_name) {
      const days = Math.ceil((endDateObj - startDateObj) / (1000 * 60 * 60 * 24));
      const weeks = Math.floor(days / 6);
      const remainingDays = days % 6;

      const calculatedQuotes = rates.map((rate) => {
        const selectedSeason = rate.seasons.find((s) => s.season_name === season.season_name);
        const quote = calculateQuote([rate], days, selectedSeason);
        return {
          ...rate,
          totalRack: quote.totalRack,
          totalNet: quote.totalNet,
        };
      });

      setQuotes(calculatedQuotes);
      setSummary(`Total rental period: ${weeks} week(s) and ${remainingDays} day(s) | Season: ${season.season_name}`);
    } else {
      setSummary('Error: Unable to determine season.');
    }
  }, [rates, startDate, endDate]);

  const determineSeason = (targetDate, seasons) => {
    return seasons.find((season) => {
      const seasonStartDate = dayjs(season.start_date, 'YYYY-MM-DD').startOf('day');
      const seasonEndDate = dayjs(season.end_date, 'YYYY-MM-DD').endOf('day');
      return dayjs(targetDate).isBetween(seasonStartDate, seasonEndDate, null, '[]'); // Inclusive of boundaries
    });
  };

  const handleCopyToClipboard = (quote) => {
        // Use dayjs to format the dates without timezone issues
        const startDateFormatted = dayjs(startDate, 'YYYY-MM-DD').format('DD-MMM-YYYY');
        const endDateFormatted = dayjs(endDate, 'YYYY-MM-DD').format('DD-MMM-YYYY');
    
        // Construct the text to copy
        const textToCopy = `${startDateFormatted} - ${endDateFormatted}\tSIXT\t${quote.category} ${quote.car}\t${quote.totalNet?.toFixed(2) || 'N/A'}\t${quote.totalRack?.toFixed(2) || 'N/A'}`;
    
        // Write to the clipboard
        navigator.clipboard.writeText(textToCopy).then(() => {
          setCopyButtonText('Copied!');
          setTimeout(() => {
            setCopyButtonText('');
          }, 2000);
        });
      };
    
      const columns = [
        { title: 'Category', dataIndex: 'category', key: 'category' },
        { title: 'Car', dataIndex: 'car', key: 'car' },
        { title: 'Transmission', dataIndex: 'transmission', key: 'transmission' },
        { title: 'Passengers', dataIndex: 'passengers', key: 'passengers' },
        { title: 'Fuel', dataIndex: 'fuel', key: 'fuel' },
        { title: 'Total Net Rate', dataIndex: 'totalNet', key: 'totalNet', render: (value) => `$${value?.toFixed(2) || 'N/A'}` },
        { title: 'Total Rack Rate', dataIndex: 'totalRack', key: 'totalRack', render: (value) => `$${value?.toFixed(2) || 'N/A'}` },
        {
          title: '',
          key: 'copy',
          render: (_, quote) => (
            <Button onClick={() => handleCopyToClipboard(quote)}>
              {copyButtonText || 'Copy'}
            </Button>
          ),
        },
      ];
    
      if (!startDate || !endDate) {
        return (
          <div className={styles.centeredContainer}>
            <Typography.Text>Select dates for rates and quotes</Typography.Text>
          </div>
        );
      }
    
      return (
        <div className={styles.quoteGeneratorContainer}>
          <Title level={3} className={styles.quoteGeneratorTitle}>
            Rental Car Quotes
          </Title>
          <Table
            dataSource={quotes}
            columns={columns}
            rowKey={(quote) => quote.id}
            pagination={false}
            className={styles.rateTableContainer}
            footer={() => <div>{summary}</div>}
          />
        </div>
      );
    };
    
    export default RentalCarsQuoteGenerator;
    
    