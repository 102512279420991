// File: src/services/toursTransformers.js
// Purpose: Provides utility functions to extract, transform, and shape API responses into usable frontend data formats.
// Files it interacts with:
//   - src/redux/middleware/toursMiddleware.js: Middleware uses these functions to transform data after fetching it from the API.
//   - src/services/tours_api.js: Provides raw API responses for transformation.

export const transformYears = (rawData) => {
    console.log('toursTransformers: Transforming years data:', rawData);
    return rawData.map((item) => ({
        id: item.id,
        year: item.year,
    }));
};

export const transformRegions = (rawData) => {
    console.log('toursTransformers: Transforming regions data:', rawData);
    return rawData
        .map((item) => ({
            id: item.id,
            name: item.name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically by name
};

export const transformActivities = (rawData) => {
    console.log('toursTransformers: Transforming activities data:', rawData);
    return rawData
        .map((item) => ({
            id: item.id,
            name: item.name,
            providerId: item.provider_id || null, // Map provider ID
            providerName: item.provider_name || 'Provider', // Map provider name
        }))
        .sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically by name
};

export const transformRates = (rawData) => {
    console.log('toursTransformers: Transforming rates data:', rawData);
    return rawData.map((item) => ({
        id: item.id || null, // Ensure the ID of the rate is mapped
        activity: item.activity || 'N/A', // Activity name or default
        reg_adults_rack: parseFloat(item.reg_adults_rack) || 0,
        reg_adults_net: parseFloat(item.reg_adults_net) || 0,
        reg_children_rack: parseFloat(item.reg_children_rack) || 0,
        reg_children_net: parseFloat(item.reg_children_net) || 0,
        priv_adults_rack: parseFloat(item.priv_adults_rack) || 0,
        priv_adults_net: parseFloat(item.priv_adults_net) || 0,
        priv_children_rack: parseFloat(item.priv_children_rack) || 0,
        priv_children_net: parseFloat(item.priv_children_net) || 0,
        min_pax: item.min_pax || 1, // Default minimum pax
        min_age: item.min_age || 0, // Default minimum age
        providerId: item.provider_id || null, // Correctly map provider_id
        providerName: item.provider_name || 'Unknown', // Correctly map provider_name
    }));
};

// Handles paginated responses: Flattens and extracts results
export const flattenPaginatedResponse = (rawResponse) => {
    console.log('toursTransformers: Flattening paginated response:', rawResponse);
    if (!rawResponse || !rawResponse.results) {
        console.error('Invalid paginated response format:', rawResponse);
        return [];
    }
    return rawResponse.results.map((item) => ({ ...item }));
};