// File: src/redux/middleware/domesticFlightsOperationsMiddleware.js
// Purpose: Handles API calls and side effects for domestic flights operations (rates, policies).

import { createListenerMiddleware } from '@reduxjs/toolkit';
import {
  fetchRatesFulfilled,
  fetchRatesRejected,
  resetRates,
  fetchPolicyFulfilled,
  fetchPolicyRejected,
} from '../slices/domesticFlightsOperationsSlice';
import { setSelectedDropoffLocationId } from '../slices/domesticFlightsDataSlice';
import { apiUtils as domesticFlightsApi } from 'services/domestic_flights_api';
import { transformFlightRates, transformPolicies } from 'services/domesticFlightsTransformers';
import { addLog } from '../../components/Dashboard/DomesticFlights/LogViewer'; // Logging utility

// Create Listener Middleware
const domesticFlightsOperationsListenerMiddleware = createListenerMiddleware();

// Listener for Fetching Rates
domesticFlightsOperationsListenerMiddleware.startListening({
  actionCreator: setSelectedDropoffLocationId,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    addLog('Listener', 'Fetching flight rates triggered', { state });

    const { selectedDate, selectedPickupLocationId, selectedDropoffLocationId } = state.domesticFlightsData;

    if (!selectedDate || !selectedPickupLocationId || !selectedDropoffLocationId) {
      addLog('Listener', 'Required data missing for fetching rates', {
        selectedDate,
        selectedPickupLocationId,
        selectedDropoffLocationId,
      });
      listenerApi.dispatch(resetRates());
      return;
    }

    try {
      addLog('Listener', 'Fetching flight rates', { selectedDate, selectedPickupLocationId, selectedDropoffLocationId });

      const rawData = await domesticFlightsApi.fetchFlightPricingByRoute(
        selectedDate,
        selectedPickupLocationId,
        selectedDropoffLocationId
      );
      addLog('API', 'Flight pricing API call successful', { rawData });

      const transformedData = transformFlightRates(rawData);
      addLog('Transformer', 'Flight rates transformed successfully', { transformedData });

      listenerApi.dispatch(fetchRatesFulfilled(transformedData));
      addLog('Listener', 'Dispatched fetchRatesFulfilled action', { transformedData });
    } catch (error) {
      const errorDetails = error.response
        ? { status: error.response.status, data: error.response.data }
        : { message: error.message || 'Unknown error', stack: error.stack };

      addLog('Listener', 'Error fetching flight rates', { error: errorDetails });

      listenerApi.dispatch(fetchRatesRejected(errorDetails));
    }
  },
});

// Listener for Changes in Pricing ID
domesticFlightsOperationsListenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    // Detect if pricing_ID (selectedPricingId) has changed
    const currentPricingId = currentState.domesticFlightsOperations.selectedPricingId;
    const previousPricingId = previousState.domesticFlightsOperations.selectedPricingId;

    return currentPricingId !== previousPricingId;
  },
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    const { selectedPricingId } = state.domesticFlightsOperations;

    if (!selectedPricingId) {
      addLog('Listener', 'Pricing ID is null. Skipping policy fetch.');
      return; // Exit if pricing_ID is null
    }

    try {
      addLog('Listener', 'Fetching provider policy for pricing_ID', { selectedPricingId });

      const rawData = await domesticFlightsApi.fetchPolicyTextByPricingId(selectedPricingId);
      addLog('API', 'Provider policy API call successful', { rawData });

      const transformedData = transformPolicies(rawData);
      addLog('Transformer', 'Provider policy transformed successfully', { transformedData });

      listenerApi.dispatch(fetchPolicyFulfilled(transformedData));
      addLog('Listener', 'Dispatched fetchPolicyFulfilled action', { transformedData });
    } catch (error) {
      const errorDetails = error.response
        ? { status: error.response.status, data: error.response.data }
        : { message: error.message || 'Unknown error', stack: error.stack };

      addLog('Listener', 'Error fetching provider policy', { error: errorDetails });

      listenerApi.dispatch(fetchPolicyRejected(errorDetails));
    }
  },
});

export default domesticFlightsOperationsListenerMiddleware;