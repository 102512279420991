// File: src/redux/middleware/domesticFlightsDataMiddleware.js
// Purpose: Handles API calls and side effects for domestic flights data (dates, pickup, dropoff).

import { createListenerMiddleware } from '@reduxjs/toolkit';
import { setSelectedDate, setSelectedPickupLocationId } from '../slices/domesticFlightsDataSlice';
import { apiUtils as domesticFlightsApi } from 'services/domestic_flights_api'; // Updated import
import { transformPickupLocations, transformDropoffLocations } from 'services/domesticFlightsTransformers'; // Import transformers
import { addLog } from '../../components/Dashboard/DomesticFlights/LogViewer'; // Logging utility

// Create Listener Middleware
const domesticFlightsDataListenerMiddleware = createListenerMiddleware();

// Listener for setSelectedDate -> Trigger fetchPickupLocations API Call
domesticFlightsDataListenerMiddleware.startListening({
  actionCreator: setSelectedDate,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    const selectedDate = state.domesticFlightsData.selectedDate;

    if (!selectedDate) {
      addLog('Listener', 'No date selected, skipping fetchPickupLocations');
      return;
    }

    try {
      const rawData = await domesticFlightsApi.fetchPickupLocationsByDate(selectedDate);

      // Transform raw data
      const transformedData = transformPickupLocations(rawData);
      addLog('Listener', 'Pickup locations transformed successfully', { transformedData });

      listenerApi.dispatch({
        type: 'domesticFlightsData/fetchPickupLocations/fulfilled',
        payload: transformedData,
      });
    } catch (error) {
      const errorDetails = error.response
        ? { status: error.response.status, data: error.response.data }
        : { message: error.message || 'Unknown error', stack: error.stack };

      addLog('Listener', 'Error fetching pickup locations', { error: errorDetails });
      listenerApi.dispatch({
        type: 'domesticFlightsData/fetchPickupLocations/rejected',
        payload: errorDetails,
      });
    }
  },
});

// Listener for setSelectedPickupLocationId -> Trigger fetchDropoffLocations API Call
domesticFlightsDataListenerMiddleware.startListening({
  actionCreator: setSelectedPickupLocationId,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    const { selectedDate, selectedPickupLocationId } = state.domesticFlightsData;

    if (!selectedDate || !selectedPickupLocationId) {
      addLog('Listener', 'Required data missing, skipping fetchDropoffLocations', {
        selectedDate,
        selectedPickupLocationId,
      });
      return;
    }

    try {
      addLog('Listener', 'Fetching dropoff locations', { selectedDate, selectedPickupLocationId });
      const rawData = await domesticFlightsApi.fetchDropoffLocationsByPickup(
        selectedDate,
        selectedPickupLocationId
      );

      // Transform raw data
      const transformedData = transformDropoffLocations(rawData);
      addLog('Listener', 'Dropoff locations transformed successfully', { transformedData });

      listenerApi.dispatch({
        type: 'domesticFlightsData/fetchDropoffLocations/fulfilled',
        payload: transformedData,
      });
    } catch (error) {
      const errorDetails = error.response
        ? { status: error.response.status, data: error.response.data }
        : { message: error.message || 'Unknown error', stack: error.stack };

      addLog('Listener', 'Error fetching dropoff locations', { error: errorDetails });
      listenerApi.dispatch({
        type: 'domesticFlightsData/fetchDropoffLocations/rejected',
        payload: errorDetails,
      });
    }
  },
});

export default domesticFlightsDataListenerMiddleware;