// File: src/redux/slices/hotelLocationSlice.js
// Purpose: Ensure correct auto-selection logic for the first country and trigger region fetching.

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchCountries,
  fetchRegions,
  fetchHotels,
  fetchRoomTypes,
} from "../../services/hotelSearchApiService";
import { addLog } from "../../components/Dashboard/HotelRatesSearch/LogViewer";

// Async thunks for fetching hierarchical location data
export const fetchCountriesAsync = createAsyncThunk(
  "hotelLocation/fetchCountries",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const countries = await fetchCountries();
      addLog("Redux Thunk", "Fetched countries successfully", countries);

      if (countries.length > 0) {
        const firstCountryId = countries[0].id;

        // Auto-select the first country and trigger regions fetching
        dispatch(setSelectedCountry(firstCountryId));
        dispatch(fetchRegionsAsync(firstCountryId));
      }

      return countries;
    } catch (error) {
      addLog("Redux Error", "Failed to fetch countries", error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchRegionsAsync = createAsyncThunk(
  "hotelLocation/fetchRegions",
  async (countryId, { rejectWithValue }) => {
    try {
      const regions = await fetchRegions(countryId);
      addLog("Redux Thunk", "Fetched regions successfully", { countryId, regions });
      return regions;
    } catch (error) {
      addLog("Redux Error", "Failed to fetch regions", error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchHotelsAsync = createAsyncThunk(
  "hotelLocation/fetchHotels",
  async (regionId, { rejectWithValue }) => {
    try {
      const hotels = await fetchHotels(regionId || undefined); // Handle all hotels if regionId is null
      addLog("Redux Thunk", "Fetched hotels successfully", { regionId, hotels });
      return hotels;
    } catch (error) {
      addLog("Redux Error", "Failed to fetch hotels", error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchRoomTypesAsync = createAsyncThunk(
  "hotelLocation/fetchRoomTypes",
  async (hotelId, { rejectWithValue }) => {
    try {
      const roomTypes = await fetchRoomTypes(hotelId);
      addLog("Redux Thunk", "Fetched room types successfully", { hotelId, roomTypes });
      return roomTypes;
    } catch (error) {
      addLog("Redux Error", "Failed to fetch room types", error.message);
      return rejectWithValue(error.message);
    }
  }
);

// Slice definition
const hotelLocationSlice = createSlice({
  name: "hotelLocation",
  initialState: {
    countries: [],
    regions: [],
    hotels: [],
    roomTypes: [],
    selectedCountry: null,
    selectedRegion: null,
    selectedHotel: null,
    selectedRoomType: null,
    loading: false,
    error: null,
    fetchStatus: "idle", // 'idle' | 'loading' | 'success' | 'error'
  },
  reducers: {
    triggerCountryFetch(state) {
      addLog("Redux Action", "triggerCountryFetch dispatched.");
      state.fetchStatus = "idle"; // Reset fetch status
    },
    setSelectedCountry(state, action) {
      addLog("Redux Reducer", "Set selected country", {
        before: state.selectedCountry,
        after: action.payload,
      });
      state.selectedCountry = action.payload || null;
      state.selectedRegion = null;
      state.selectedHotel = null;
      state.roomTypes = [];
    },
    setSelectedRegion(state, action) {
      addLog("Redux Reducer", "Set selected region", {
        before: state.selectedRegion,
        after: action.payload,
      });
      state.selectedRegion = action.payload || null;
      state.selectedHotel = null;
      state.roomTypes = [];
    },
    setSelectedHotel(state, action) {
      addLog("Redux Reducer", "Set selected hotel", {
        before: state.selectedHotel,
        after: action.payload,
      });
      state.selectedHotel = action.payload || null;

      // Reverse lookup: Clear or set region and country based on selected hotel
      const hotel = state.hotels.find((h) => h.id === action.payload);
      if (hotel) {
        state.selectedRegion = hotel.region_id || null;
        state.selectedCountry = hotel.country_id || null;
        addLog("Reverse Lookup", "Region and country set based on selected hotel", {
          regionId: hotel.region_id,
          countryId: hotel.country_id,
        });
      } else {
        state.selectedRegion = null;
        state.selectedCountry = null;
      }
    },
    setSelectedRoomType(state, action) {
      addLog("Redux Reducer", "Set selected room type", {
        before: state.selectedRoomType,
        after: action.payload,
      });
      state.selectedRoomType = action.payload || null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountriesAsync.pending, (state) => {
        state.fetchStatus = "loading";
        state.loading = true;
      })
      .addCase(fetchCountriesAsync.fulfilled, (state, action) => {
        state.countries = action.payload || [];
        state.fetchStatus = "success";
        state.loading = false;

        addLog("Auto-Select", "First country auto-selected", {
          selectedCountry: state.selectedCountry,
        });
      })
      .addCase(fetchCountriesAsync.rejected, (state, action) => {
        state.error = action.payload;
        state.fetchStatus = "error";
        state.loading = false;
      })
      .addCase(fetchRegionsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRegionsAsync.fulfilled, (state, action) => {
        state.regions = action.payload || [];
        state.loading = false;
      })
      .addCase(fetchRegionsAsync.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchHotelsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHotelsAsync.fulfilled, (state, action) => {
        state.hotels = action.payload || [];
        state.loading = false;
      })
      .addCase(fetchHotelsAsync.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchRoomTypesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRoomTypesAsync.fulfilled, (state, action) => {
        state.roomTypes = action.payload || [];
        state.loading = false;
      })
      .addCase(fetchRoomTypesAsync.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

// Export actions
export const {
  triggerCountryFetch,
  setSelectedCountry,
  setSelectedRegion,
  setSelectedHotel,
  setSelectedRoomType,
} = hotelLocationSlice.actions;

// Export reducer
export default hotelLocationSlice.reducer;