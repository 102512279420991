// File: src/components/Dashboard/DomesticFlights/DomesticFlightsDestinationDropdown.js
// Purpose: Dropdown for selecting a destination airport based on the selected origin.

import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSelectedDropoffLocationId,
  clearDropoffLocations,
} from '../../../redux/slices/domesticFlightsDataSlice';
import {
  selectDropoffLocations,
  selectIsLoadingDropoffLocations,
  selectDropoffLocationsError,
  selectSelectedPickupLocationId,
  selectSelectedDate,
  selectSelectedDropoffLocationId,
} from '../../../redux/slices/domesticFlightsSelectors';
import { addLog } from './LogViewer'; // Import addLog for debugging
import styles from './domesticFlights.module.css';

const { Option } = Select;

const DomesticFlightsDestinationDropdown = () => {
  const dispatch = useDispatch();
  const destinations = useSelector(selectDropoffLocations);
  const selectedPickupLocationId = useSelector(selectSelectedPickupLocationId);
  const selectedDate = useSelector(selectSelectedDate);
  const selectedDropoffLocationId = useSelector(selectSelectedDropoffLocationId);
  const loadingDestinations = useSelector(selectIsLoadingDropoffLocations);
  const errorDestinations = useSelector(selectDropoffLocationsError);

  useEffect(() => {
    if (!selectedPickupLocationId || !selectedDate) {
      addLog('Component', 'Dispatching clearDropoffLocations', {});
      dispatch(clearDropoffLocations());
    } else {
      addLog(
        'Component',
        'State relies on middleware to fetch dropoff locations',
        { selectedPickupLocationId, selectedDate }
      );
    }
  }, [selectedPickupLocationId, selectedDate, dispatch]);

  const handleDestinationSelect = (value) => {
    addLog('Component', 'setSelectedDropoffLocationId called', { value });
    dispatch(setSelectedDropoffLocationId(value));
  };

  return (
    <div className={styles.dropdownWrapper}>
      <Select
        showSearch
        value={selectedDropoffLocationId}
        onChange={handleDestinationSelect}
        placeholder={
          loadingDestinations
            ? 'Loading destinations...'
            : errorDestinations
            ? 'Failed to load destinations'
            : 'Select a destination airport'
        }
        allowClear
        className={`${styles.dropdown} ${styles.destinationDropdown}`}
        style={{ width: '100%' }}
        notFoundContent={
          loadingDestinations
            ? 'Loading...'
            : errorDestinations
            ? errorDestinations
            : 'No destinations available'
        }
        disabled={!selectedPickupLocationId || !selectedDate || loadingDestinations}
      >
        {Array.isArray(destinations) &&
          destinations.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
      </Select>
    </div>
  );
};

export default DomesticFlightsDestinationDropdown;