// File: src/components/Dashboard/Tours/ToursRatesSection.js
// Purpose: Displays the rates for a selected tour.
// Related files:
//   - src/redux/slices/toursOperationsSlice.js: Manages fetching and state for rates.
//   - ToursRatesTable.js: Displays the rates in a table format.

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRates } from '../../../redux/slices/toursOperationsSlice';
import {
    selectRates,
    selectLoadingRates,
    selectErrorRates,
    selectSelectedActivityId,
} from '../../../redux/slices/toursSelectors';
import ToursRatesTable from './ToursRatesTable';
import styles from 'styles/Tours.module.css';

const ToursRatesSection = () => {
    const dispatch = useDispatch();
    const rates = useSelector(selectRates);
    const loadingRates = useSelector(selectLoadingRates);
    const errorRates = useSelector(selectErrorRates);
    const selectedActivityId = useSelector(selectSelectedActivityId);

    useEffect(() => {
        if (selectedActivityId) {
            console.log('ToursRatesSection: Fetching rates for activityId:', selectedActivityId);
            dispatch(fetchRates(selectedActivityId));
        } else {
            console.log('ToursRatesSection: No activity selected, rates not fetched.');
        }
    }, [selectedActivityId, dispatch]);

    if (loadingRates) {
        return <div className={styles.ratesContainer}>Loading...</div>;
    }

    if (errorRates) {
        return (
            <div className={styles.ratesContainer}>
                <p className={styles.errorMessage}>Failed to fetch rates: {errorRates}</p>
            </div>
        );
    }

    if (!rates || rates.length === 0) {
        return <div className={styles.noRatesMessage}>Select a tour for rates and quotes.</div>;
    }

    return (
        <div className={styles.ratesContainer}>
            <ToursRatesTable rates={rates} />
        </div>
    );
};

export default ToursRatesSection;