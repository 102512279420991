// File: src/redux/slices/toursSelectors.js
// Purpose: Centralizes reusable selectors for the Tours feature, improving maintainability and reducing duplication in components.
// Files it interacts with:
//   - src/redux/slices/toursDataSlice.js: Provides state for years, regions, and activities.
//   - src/redux/slices/toursOperationsSlice.js: Provides state for rates, policies, and quotes.

export const selectAvailableYears = (state) => state.toursData.years;
export const selectLoadingYears = (state) => state.toursData.loadingYears;
export const selectErrorYears = (state) => state.toursData.errorYears;

export const selectRegions = (state) => state.toursData.regions;
export const selectLoadingRegions = (state) => state.toursData.loadingRegions;
export const selectErrorRegions = (state) => state.toursData.errorRegions;

export const selectActivities = (state) => state.toursData.activities;
export const selectLoadingActivities = (state) => state.toursData.loadingActivities;
export const selectErrorActivities = (state) => state.toursData.errorActivities;

export const selectRates = (state) => state.toursOperations.rates;
export const selectLoadingRates = (state) => state.toursOperations.loadingRates;
export const selectErrorRates = (state) => state.toursOperations.errorRates;

export const selectPolicyText = (state) => state.toursOperations.policy;
export const selectLoadingPolicy = (state) => state.toursOperations.loadingPolicy;
export const selectErrorPolicy = (state) => state.toursOperations.errorPolicy;

export const selectSelectedYearId = (state) => state.toursData.selectedYearId;
export const selectSelectedRegionId = (state) => state.toursData.selectedRegionId;
export const selectSelectedActivityId = (state) => state.toursData.selectedActivityId;
export const selectSelectedActivityName = (state) =>
    state.toursData.activities.find((activity) => activity.id === state.toursData.selectedActivityId)?.name || 'N/A';

export const selectSelectedProviderId = (state) => state.toursOperations.selectedProviderId;

export const selectSelectedProviderName = (state) => state.toursOperations.selectedProviderName;

export const selectRegularAdults = (state) => state.toursOperations.regularAdults;
export const selectRegularChildren = (state) => state.toursOperations.regularChildren;
export const selectPrivateAdults = (state) => state.toursOperations.privateAdults;
export const selectPrivateChildren = (state) => state.toursOperations.privateChildren;
export const selectAdditionalAmount = (state) => state.toursOperations.additionalAmount;

export const selectNetQuote = (state) => state.toursOperations.netQuote;
export const selectRackQuote = (state) => state.toursOperations.rackQuote;