// File: src/components/Dashboard/HotelRatesSearch/index.js
// Purpose: Updated to trigger fetching all hotels and integrate reverse lookup logic.
//          Integrates HotelSearchBar, HotelRatesDisplay, HotelInformation, and LogViewer components.

/*
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { triggerCountryFetch, fetchHotelsAsync } from "../../../redux/slices/hotelLocationSlice";
import HotelSearchBar from "./HotelSearchBar";
import HotelRatesDisplay from "./HotelRatesDisplay";
import HotelInformation from "./HotelInformation";
import LogViewer from "./LogViewer"; // Import the Log Viewer component
import { Layout } from "antd";
import styles from "styles/HotelRatesSearch.module.css";

const { Content } = Layout;

const HotelRatesSearch = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(triggerCountryFetch()); // Trigger the fetch of countries
    dispatch(fetchHotelsAsync(null)); // Trigger the fetch of all hotels
  }, [dispatch]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content style={{ padding: "24px", backgroundColor: "#fff" }}>
        <div className={styles.hotelSearchContainer}>
          <HotelSearchBar />
          <HotelRatesDisplay />
          <HotelInformation />
          <LogViewer />
        </div>
      </Content>
    </Layout>
  );
};

export default HotelRatesSearch;
*/

import React from "react";

const HotelRatesSearch = () => {
  return (
    <div style={{ textAlign: "center", padding: "50px", fontSize: "18px", color: "#888" }}>
      Coming Soon...
    </div>
  );
};

export default HotelRatesSearch;