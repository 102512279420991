// File: src/redux/middleware/ratesMiddleware.js
// Purpose: Monitors actions related to selected hotel, room type, and dates to trigger rates fetching.

import { fetchRatesAsync } from "../slices/hotelRatesSlice";
import { addLog } from "../../components/Dashboard/HotelRatesSearch/LogViewer";

let isRatesMiddlewareInitialized = false;

const ratesMiddleware = (store) => (next) => (action) => {
  const logDetails = (message, data = null) => {
    addLog("Middleware", message, { actionType: action.type, payload: action.payload, ...data });
  };

  if (!isRatesMiddlewareInitialized) {
    logDetails("ratesMiddleware initialized");
    isRatesMiddlewareInitialized = true;
  }

  next(action);

  const state = store.getState();
  const { selectedHotel, selectedRoomType, selectedDates } = state.hotelLocation;

  // Monitored actions that can trigger fetchRatesAsync
  const monitoredActions = [
    "hotelLocation/setSelectedHotel",
    "hotelLocation/setSelectedRoomType",
    "hotelLocation/setDates",
  ];

  if (monitoredActions.includes(action.type)) {
    logDetails("Action received", { selectedHotel, selectedRoomType, selectedDates });

    // Check if all necessary conditions are met for fetching rates
    if (!selectedHotel || !selectedRoomType || !selectedDates?.checkIn || !selectedDates?.checkOut) {
      logDetails("Conditions not met for API call", { selectedHotel, selectedRoomType, selectedDates });
      return; // Exit if conditions are not satisfied
    }

    logDetails("All conditions met. Dispatching fetchRatesAsync", {
      roomTypeId: selectedRoomType,
      startDate: selectedDates.checkIn,
      endDate: selectedDates.checkOut,
    });

    // Dispatch fetchRatesAsync with the selected parameters
    store.dispatch(
      fetchRatesAsync({
        roomTypeId: selectedRoomType,
        startDate: selectedDates.checkIn,
        endDate: selectedDates.checkOut,
      })
    );
  }
};

export default ratesMiddleware;