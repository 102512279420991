// File: src/components/Dashboard/Tours/ToursPolicySection.js
// Purpose: Displays the policy section for a selected tour provider. Pulls all data from Redux.
// Files it interacts with:
//   - src/redux/slices/toursSelectors.js: Fetches Redux state values for policies and provider details.

import React from 'react';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import {
    selectPolicyText,
    selectLoadingPolicy,
    selectErrorPolicy,
    selectSelectedProviderName,
} from '../../../redux/slices/toursSelectors';
import styles from 'styles/Tours.module.css';

const { Title } = Typography;

const ToursPolicySection = () => {
    // Access Redux state using selectors
    const policyText = useSelector(selectPolicyText);
    const loadingPolicy = useSelector(selectLoadingPolicy);
    const errorPolicy = useSelector(selectErrorPolicy);
    const providerName = useSelector(selectSelectedProviderName);

    // Render loading state
    if (loadingPolicy) {
        return <div className={styles.policyContainer}>Loading...</div>;
    }

    // Render error state
    if (errorPolicy) {
        return (
            <div className={styles.policyContainer}>
                <p className={styles.errorMessage}>Failed to fetch policy: {errorPolicy}</p>
            </div>
        );
    }

    // Render policy text or fallback message
    const renderedPolicyText = policyText || '<p>No policy available.</p>';

    return (
        <div className={styles.policyContainer}>
            <Title level={3}>{providerName ? `${providerName} Policies` : 'Policies'}</Title>
            <div
                className={styles.policyContent}
                dangerouslySetInnerHTML={{ __html: renderedPolicyText }}
            />
        </div>
    );
};

export default ToursPolicySection;