// File: src/redux/middleware/toursMiddleware.js
// Purpose: Middleware to handle side effects for Tours actions, including resetting rates, fetching activities, and managing provider details and policies.
// Files it interacts with:
//   - src/redux/slices/toursDataSlice.js: Manages regions and activities.
//   - src/redux/slices/toursOperationsSlice.js: Manages rates, provider details, and policies.
//   - src/services/tours_api.js: Provides API methods for fetching raw data.

import {
    setSelectedRegionId,
    fetchActivities,
} from '../slices/toursDataSlice';
import {
    resetRates,
    fetchRates,
    setSelectedProviderId,
    setSelectedProviderName,
    fetchPolicy,
} from '../slices/toursOperationsSlice';
import { fetchPolicyTextByProviderId } from '../../services/tours_api'; // Import API function

const toursMiddleware = (store) => (next) => async (action) => {
    const state = store.getState();

    switch (action.type) {
        // Handle region changes
        case setSelectedRegionId.type: {
            const newRegionId = action.payload;
            const currentRegionId = state.toursData.selectedRegionId;

            // Prevent duplicate actions if the region hasn't changed
            if (newRegionId !== currentRegionId) {
                console.log(`Region changed to: ${newRegionId}`);
                
                // Reset rates and activities when the region changes
                store.dispatch(resetRates());
                if (newRegionId) {
                    store.dispatch(fetchActivities(newRegionId));
                }
            }
            break;
        }

        // Handle rates fetch success
        case fetchRates.fulfilled.type: {
            if (action.payload.length > 0) {
                const { providerId, providerName } = action.payload[0];
                const currentProviderId = state.toursOperations.selectedProviderId;

                // Update provider details if they have changed
                if (providerId && providerId !== currentProviderId) {
                    console.log(`Provider changed to: ${providerName} (ID: ${providerId})`);
                    store.dispatch(setSelectedProviderId(providerId));
                    store.dispatch(setSelectedProviderName(providerName || 'Unknown'));

                    // Fetch provider policy
                    store.dispatch(fetchPolicy(providerId));
                }
            }
            break;
        }

        // Handle provider policy fetch
        case fetchPolicy.type: {
            try {
                const providerId = action.payload;

                if (!providerId) {
                    console.warn('fetchPolicy called with null or undefined providerId');
                    break;
                }

                const rawPolicy = await fetchPolicyTextByProviderId(providerId);
                const policyText = rawPolicy.policy_text || 'Policy text unavailable';
                store.dispatch(fetchPolicy.fulfilled(policyText));
            } catch (error) {
                console.error('Error fetching policy:', error.message);
                store.dispatch(fetchPolicy.rejected(error.message));
            }
            break;
        }

        default:
            break;
    }

    return next(action);
};

export default toursMiddleware;